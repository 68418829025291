import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Router, Switch } from 'react-router-dom';

import { useIntl } from 'hooks';
import { historyService } from 'services';

import RouteBoundary from 'components/@boundaries/RouteBoundary';
import { RoutePublic } from 'components/@common/Route';

import NotFound from './not-found/NotFound';
import routes from './routes';

const Pages = () => {
  const intl = useIntl();

  return (
    <RouteBoundary>
      <Helmet>
        <title>{intl.formatMessage({ id: 'seo.title' })}</title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'seo.description' })}
        />
      </Helmet>
      <Router history={historyService.history}>
        <Suspense fallback={null}>
          <Switch>
            {routes.map((route) => {
              const routeProps = {
                key: route.path?.toString(),
                exact: route.exact,
                path: route.path,
                component: route.component,
              };

              return <RoutePublic {...routeProps} key={routeProps.key} />;
            })}
            <RoutePublic component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </RouteBoundary>
  );
};

export default Pages;
