import { Box, BoxProps } from '@material-ui/core';
import React from 'react';

interface Props {
  component?: BoxProps['component'];
  uppercase?: boolean;
}
export const Black: React.FC<Props> = ({
  children,
  component = 'span',
  uppercase = false,
}) => (
  <Box
    fontWeight={900}
    component={component}
    style={{ textTransform: uppercase ? 'uppercase' : undefined }}
  >
    {children}
  </Box>
);
