import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoAutoLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none" {...props}>
    <g clipPath="url(#clip0_151_10242)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.512 18.3105C13.0534 18.756 12.4313 19.0063 11.7827 19.0063C11.134 19.0063 10.512 18.756 10.0533 18.3105C9.59466 17.8649 9.337 17.2606 9.337 16.6305C9.337 16.0004 9.59466 15.3961 10.0533 14.9506C10.512 14.505 11.134 14.2547 11.7827 14.2547C13.1278 14.2547 14.2283 15.312 14.2283 16.6305C14.2283 17.2606 13.9707 17.8649 13.512 18.3105ZM20.849 9.01112C20.3904 8.56557 19.7683 8.31526 19.1197 8.31526H17.8968V5.93947C17.8968 5.15949 17.7387 4.38715 17.4314 3.66653C17.1241 2.94592 16.6738 2.29116 16.106 1.73963C15.5383 1.1881 14.8643 0.750602 14.1224 0.452115C13.3806 0.153629 12.5856 0 11.7827 0C10.1611 0 8.60592 0.625764 7.45929 1.73963C6.31267 2.8535 5.6685 4.36423 5.6685 5.93947V8.31526H4.44567C3.10055 8.31526 2 9.37249 2 10.6911V22.57C2 23.2001 2.25767 23.8044 2.71632 24.2499C3.17497 24.6955 3.79703 24.9458 4.44567 24.9458H12.4268C12.4261 24.8959 12.4258 24.8459 12.4258 24.7959C12.4258 19.3985 16.3898 14.9269 21.5653 14.1336V10.6911C21.5653 10.061 21.3077 9.45666 20.849 9.01112ZM9.18864 3.41957C9.87662 2.75125 10.8097 2.37579 11.7827 2.37579C12.7556 2.37579 13.6887 2.75125 14.3767 3.41957C15.0647 4.08789 15.4512 4.99433 15.4512 5.93947V8.31526H8.11416V5.93947C8.11416 4.99433 8.50066 4.08789 9.18864 3.41957Z"
        fill="white"
      />
      <path
        d="M23.213 31.538C19.5048 31.538 16.4709 28.5041 16.4709 24.7959C16.4709 21.0878 19.5048 18.0538 23.213 18.0538C26.9212 18.0538 29.9551 21.0878 29.9551 24.7959C29.9551 28.5041 26.9212 31.538 23.213 31.538ZM23.213 16.3683C18.5778 16.3683 14.7854 20.1607 14.7854 24.7959C14.7854 29.4311 18.5778 33.2235 23.213 33.2235C27.8482 33.2235 31.6406 29.4311 31.6406 24.7959C31.6406 20.1607 27.8482 16.3683 23.213 16.3683ZM22.7916 25.4701L26.8369 27.8299L27.4268 26.65L24.0558 24.7116V20.5821H22.7916V25.4701Z"
        fill="#FD14D8"
      />
    </g>
    <defs>
      <clipPath id="clip0_151_10242">
        <rect width={34} height={34} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgIcoAutoLock);
export default Memo;
