import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

const Button = styled(IconButton)`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 95, 207, 0) 0%,
    #005fcf 100%
  );
  height: 40px;
  width: 40px;
  pointer-events: auto;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  &:disabled {
    opacity: 0;
    visibility: hidden;
  }
`;

export default Button;
