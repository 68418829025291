import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  List,
  Tab,
  Tabs,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { inIframe } from 'utils/window.utils';

import { PageView } from 'constants/analytics';
import { useAnalytics, useAuth, useIntl } from 'hooks';
import { useLeaderboard, useScoreBreakdown, useUserLeaderboard } from 'queries';
import { StaleTime } from 'queries/constants';
import { historyService } from 'services';

import Dialog from 'components/@common/Dialog';
import LightsBackground from 'components/@common/LightsBackground';
import PageContainer from 'components/@common/PageContainer';
import PageMargin from 'components/@common/PageMargin';
import PointsCard from 'components/@game/PointsCard';
import { IcoClose } from 'components/@icons';
import LeaderboardListItem from 'components/@leaderboard/LeaderboardListItem';
import PrizeCard from 'components/@leaderboard/PrizeCard';
import ScoreBreakdown from 'components/@leaderboard/ScoreBreakdown';

import { ListGradient, Title } from './Leaderboard.style';

const POSITION_SPLIT = 15;

const Leaderboard: React.VFC = () => {
  const intl = useIntl();
  const { trackPageView } = useAnalytics();
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [currentEpisode, setCurrentEpisode] = useState(true);

  useEffect(() => {
    if (currentEpisode) {
      trackPageView({ path: PageView.LeaderboardEpisode });
    } else {
      trackPageView({ path: PageView.LeaderboardGeneral });
    }
  }, [currentEpisode, trackPageView]);

  const { userId } = useAuth();
  const { scoreBreakdown } = useScoreBreakdown();
  const { leaderboard, isLoading: isLoadingLeaderboard } = useLeaderboard(
    currentEpisode,
    { staleTime: StaleTime.FIVE_MIN },
  );
  const { userLeaderboard, isLoading: isLoadingUserLeaderboard } =
    useUserLeaderboard(currentEpisode, { staleTime: StaleTime.FIVE_MIN });

  const isLoading = isLoadingUserLeaderboard || isLoadingLeaderboard;

  const userLeaderboardUserIdx =
    userLeaderboard?.findIndex((l) => l.id === userId) ?? -1;
  const currentUser = userLeaderboard?.[userLeaderboardUserIdx];
  const finalUsers =
    leaderboard?.slice(
      0,
      currentUser &&
        currentUser.position <= POSITION_SPLIT &&
        currentUser.position > 10
        ? POSITION_SPLIT
        : 10,
    ) ?? [];

  const finalUserLeaderboard = userLeaderboard;

  // Add 2 fake users when you're the last one
  if (finalUserLeaderboard) {
    const lastUser = finalUserLeaderboard[finalUserLeaderboard.length - 1];
    if (lastUser.id === userId) {
      finalUserLeaderboard.push({
        id: 'user-1',
        firstName: 'Dirk',
        lastNameInitials: 'T',
        createdAt: new Date().toISOString(),
        score: Math.max(lastUser.score - 10, 0),
        position: lastUser.position + 1,
      });
      finalUserLeaderboard.push({
        id: 'user-2',
        firstName: 'Sandra',
        lastNameInitials: 'V',
        createdAt: new Date().toISOString(),
        score: Math.max(lastUser.score - 20, 0),
        position: lastUser.position + 2,
      });
    }
  }

  const handleBack = () => {
    historyService.goBack();
  };

  return (
    <Box position="relative">
      <Box position="absolute" top={0} left={0} right={0}>
        <LightsBackground />
      </Box>
      <PageContainer pt={inIframe() ? 22 : 10} pb={10} maxWidth="xs">
        <Box
          position="absolute"
          right={0}
          top={0}
          pt={inIframe() ? 18 : 6}
          zIndex={100}
        >
          <IconButton onClick={handleBack}>
            <IcoClose />
          </IconButton>
        </Box>
        <PageMargin>
          <Title>
            <FormattedMessage id="leaderboard.title" />
          </Title>
          <Tabs
            value={currentEpisode ? 0 : 1}
            onChange={(e, index) => setCurrentEpisode(index === 0)}
          >
            <Tab label={<FormattedMessage id="leaderboard.tabs.episode" />} />
            <Tab label={<FormattedMessage id="leaderboard.tabs.general" />} />
          </Tabs>
          <Box width="100%" mt={5}>
            <PointsCard
              totalScore={currentUser?.score}
              position={currentUser?.position}
            />
          </Box>

          <Box my={6}>
            {scoreBreakdown && scoreBreakdown.breakdown.length > 0 && (
              <Link component="button" onClick={() => setShowBreakdown(true)}>
                <FormattedMessage id="leaderboard.breakdown.score.label" />
              </Link>
            )}
          </Box>

          <Box mb={12} width="100%">
            <PrizeCard
              titleId={
                currentEpisode
                  ? 'leaderboard.prize.description'
                  : 'leaderboard.prize.total.description'
              }
            />
          </Box>
        </PageMargin>
        {isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow="1"
            height="100%"
            my={10}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box width="100%" position="relative" mt={10}>
            <ListGradient />
            <List disablePadding>
              {finalUsers.map((u, i) => (
                <LeaderboardListItem
                  key={u.id}
                  position={u.position}
                  name={`${u.firstName} ${u.lastNameInitials.toUpperCase()}`}
                  score={u.score}
                  isCurrentUser={userId === u.id}
                  variant={i % 2 === 0 ? 'dark' : 'light'}
                />
              ))}
              {finalUserLeaderboard &&
                currentUser &&
                currentUser.position > POSITION_SPLIT && (
                  <>
                    <LeaderboardListItem
                      name="..."
                      variant={finalUsers.length % 2 === 0 ? 'dark' : 'light'}
                    />
                    {finalUserLeaderboard.map((u, i) => (
                      <LeaderboardListItem
                        key={u.id}
                        position={u.position}
                        name={`${u.firstName} ${u.lastNameInitials}`}
                        score={u.score}
                        isCurrentUser={userId === u.id}
                        variant={
                          (finalUsers.length + 1 + i) % 2 === 0
                            ? 'dark'
                            : 'light'
                        }
                      />
                    ))}
                  </>
                )}
            </List>
            <Box
              mt={5}
              mb={4}
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <Link
                href="https://vtm.be/i-can-see-your-voice/wedstrijdreglement-i-can-see-your-voice"
                target="_blank"
              >
                <FormattedMessage id="leaderboard.points.info.link.label" />
              </Link>
            </Box>
          </Box>
        )}
      </PageContainer>
      {scoreBreakdown && (
        <Dialog
          title={intl.formatMessage({ id: 'dialog.score_breakdown.title' })}
          onClose={() => setShowBreakdown(false)}
          open={showBreakdown}
        >
          <ScoreBreakdown breakdown={scoreBreakdown} />
        </Dialog>
      )}
    </Box>
  );
};

export default Leaderboard;
