import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0014 9.17063L17.3047 3.8674L20.1331 6.69583L14.8299 11.9991L20.1332 17.3024L17.3048 20.1309L12.0014 14.8275L6.69806 20.1309L3.86963 17.3024L9.17301 11.9991L3.86979 6.69583L6.69821 3.8674L12.0014 9.17063Z"
      fill="white"
    />
  </svg>
);

const Memo = memo(SvgIcoClose);
export default Memo;
