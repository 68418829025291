import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M14.7142 10.4286L14.7142 7.57147L8.28562 7.57147L10.7856 5.07147L9.05705 3.3429L3.3999 9.00004L9.05705 14.6572L10.7856 12.9286L8.28562 10.4286L14.7142 10.4286Z"
      fill="white"
    />
  </svg>
);

const Memo = memo(SvgIcoArrowLeft);
export default Memo;
