import React from 'react';

import imgGoodActive from 'assets/png/img-echt-active.png';
import imgGoodInActive from 'assets/png/img-echt-inactive.png';
import imgBadActive from 'assets/png/img-slecht-active.png';
import imgBadInActive from 'assets/png/img-slecht-inactive.png';

import { ButtonImg, ButtonWrap } from './VoteButton.style';

interface Props {
  variant: 'good' | 'bad';
  active?: boolean;
  onClick?: () => void;
}

const VoteButton: React.VFC<Props> = ({ onClick, variant, active }) => {
  return (
    <ButtonWrap
      onClick={onClick}
      disabled={active}
      $scale={active ? 1 : active === undefined ? 0.86 : 0.6}
    >
      {variant === 'bad' && (
        <ButtonImg
          src={active === undefined || active ? imgBadActive : imgBadInActive}
          alt="slecht"
        />
      )}
      {variant === 'good' && (
        <ButtonImg
          src={active === undefined || active ? imgGoodActive : imgGoodInActive}
          alt="echt"
        />
      )}
    </ButtonWrap>
  );
};

export default VoteButton;
