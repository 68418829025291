import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';

import GradientTypography from 'components/@common/GradientTypography';

interface Props {
  title: string;
  description?: string;
  href?: string;
  linkText: string;
  onClick?: () => void;
}

const InfoItem: React.FC<Props> = ({
  title,
  description,
  href,
  linkText,
  children,
  onClick,
}) => {
  return (
    <Box
      maxWidth="340px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      margin="0 auto"
    >
      <GradientTypography fontSize="24px" lineHeight={1} color="gradient01">
        {title}
      </GradientTypography>
      <Box mt={1} mb={6}>
        {children}
      </Box>
      {description && (
        <Box color="neutral01.500" mb={{ xs: 4, sm: 6 }}>
          <Typography>{description}</Typography>
        </Box>
      )}
      <Link
        href={href}
        onClick={onClick}
        component={href ? 'a' : 'button'}
        target="_blank"
      >
        {linkText}
      </Link>
    </Box>
  );
};

export default InfoItem;
