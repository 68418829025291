import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { useAuth } from 'hooks';
import { QueryKeys } from 'queries';
import { apiService } from 'services';
import { Guess, PostGuessBody } from 'types/guess.types';

const postGuess = async (userId: string, data: PostGuessBody) => {
  const { data: response } = await apiService.postGuess(userId, data);
  return response;
};

export const usePostGuess = (
  episodeId: string,
  config: UseMutationOptions<
    Guess,
    AxiosError,
    PostGuessBody,
    { previousGuesses: Guess[] }
  > = {},
) => {
  const queryClient = useQueryClient();
  const { userId } = useAuth();
  const { mutate, data, isLoading, error } = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (params) => postGuess(userId!, params),
    {
      ...config,
      onMutate: async (data) => {
        const guessesQueryKey = QueryKeys.guesses.byEpisode(episodeId);
        await queryClient.cancelQueries(guessesQueryKey);

        const previousGuesses =
          queryClient.getQueryData<Guess[]>(guessesQueryKey) ?? [];

        queryClient.setQueryData<Guess[]>(guessesQueryKey, (old) => {
          const newGuess: Guess = {
            ...data,
          };
          return [
            ...(old?.filter((g) => g.performerId !== newGuess.performerId) ??
              []),
            newGuess,
          ];
        });

        return { previousGuesses };
      },
      onError: (_err, _id, ctx) => {
        if (ctx?.previousGuesses) {
          queryClient.setQueryData(
            QueryKeys.guesses.byEpisode(episodeId),
            ctx.previousGuesses,
          );
        }
      },
    },
  );

  const handlePostGuess = useCallback(
    (data: PostGuessBody) => {
      if (userId) {
        mutate(data);
      }
    },
    [mutate, userId],
  );

  return {
    postGuess: handlePostGuess,
    guess: data,
    isLoading,
    error,
  };
};
