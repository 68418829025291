import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import styled from 'styled-components';

import { IcoAutoLock } from 'components/@icons';

const Wrap = styled(Box)`
  position: relative;
  border: 2px solid ${({ theme }) => theme.palette.global01[500]};

  height: 80px;
  border-radius: 50px;
  padding: ${({ theme }) => theme.spacing(5, 6)};
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  text-align: left;
`;

const TipBadge = styled.div`
  position: absolute;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.palette.global01[500]};
  padding: ${({ theme }) => theme.spacing(1, 3)};
  font-weight: 900;
  left: 0;
  top: 0;
  transform: translateY(-50%);
`;

const GameTip: React.VFC = () => {
  return (
    <Wrap>
      <TipBadge>TIP</TipBadge>
      <IcoAutoLock fontSize={40} />
      <Box pl={2} clone>
        <Typography variant="body2">
          <FormattedMessage
            id="game.pre_round.tip.label"
            values={{ b: (t: string) => <b>{t}</b> }}
          />
        </Typography>
      </Box>
    </Wrap>
  );
};

export default GameTip;
