import styled from 'styled-components';
import { Swiper } from 'swiper/react/swiper-react.js';

export const SwiperStyled = styled(Swiper)`
  overflow: hidden;
  width: 100%;

  .swiper-slide {
    opacity: 0;
  }
  .swiper-slide-visible {
    opacity: 1;
  }
`;

export const Navigation = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  pointer-events: none;
`;
