import styled from 'styled-components';

import imgPlatform from 'assets/png/img-platform.png';
import imgPlatformBad from 'assets/png/img-platform-bad.png';
import imgPlatformGood from 'assets/png/img-platform-good.png';

import GradientTypography from 'components/@common/GradientTypography';

export const PerformerImage = styled.img`
  width: 60%;
  object-fit: contain;
  pointer-events: none;
`;

export const PerformerPlatform = styled.div<{ $isReal?: boolean }>`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -8%;
  aspect-ratio: 211/88;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${({ $isReal }) => {
    const img =
      $isReal === undefined
        ? imgPlatform
        : $isReal
        ? imgPlatformGood
        : imgPlatformBad;

    return `url(${img})`;
  }};
  z-index: -1;
`;

export const PlatformOffset = styled.div`
  height: 30px;
`;

export const PerformerName = styled(GradientTypography)`
  font-weight: 900;
  filter: drop-shadow(4px 4px rgba(0, 0, 0, 0.25));
  text-transform: uppercase;
  text-align: center;
  height: fit-content;
`;
