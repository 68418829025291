import { AxiosInstance } from 'axios';

import authApi from './auth.api';
import episodeApi from './episode.api';
import gameApi from './game.api';
import guessApi from './guess.api';
import leaderboardApi from './leaderboard.api';
import scoreApi from './score.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // AUTH
  public getAuth = authApi.getAuth;

  // GAME
  public getGameState = gameApi.getGameState;

  // GUESSES
  public postGuess = guessApi.postGuess;
  public getGuesses = guessApi.getGuesses;
  public postLock = guessApi.postLock;
  public getLock = guessApi.getLock;

  // SCORE
  public getScoreBreakdown = scoreApi.getScoreBreakdown;

  // EPISODE
  public getEpisodes = episodeApi.getEpisodes;

  // LEADERBOARD
  public getLeaderBoard = leaderboardApi.getLeaderboard;
  public getUserLeaderBoard = leaderboardApi.getUserLeaderboard;
}

export default RestApiService;
