import { Theme } from '@material-ui/core';

import { createCustomMuiTheme } from '../utils/create-mui-theme';
import * as colors from './colors';
import { createOverrides } from './overrides';

let THEME = createCustomMuiTheme({
  breakpoints: {
    values: {
      xs: 300,
      sm: 768,
      md: 996,
      lg: 1200,
      xl: 1200,
    },
  },

  mixins: {},

  palette: {
    primary: {
      main: colors.global01[500],
    },
    secondary: {
      main: colors.global02[500],
    },
    text: {
      primary: colors.neutral01[500],
    },
    success: colors.succes01,
    error: colors.error01,
    ...colors,
  },

  shadows: [
    'none',
    ...new Array(24).fill('0px 2px 2px rgba(58, 58, 65, 0.15)'),
  ] as unknown as Theme['shadows'], // why: https://github.com/mui-org/material-ui/issues/8209

  transitions: {
    easing: {
      easeOut: 'cubic-bezier(.17,1.28,.8,1)',
    },
  },

  shape: {
    borderRadius: 4,
    radius: (factor) => 4 * factor,
  },

  spacing: 4,
  typography: {
    fontSize: 16,
    htmlFontSize: 16,

    fontFamily: 'Montserrat, sans-serif',

    h1: {
      fontSize: 40,
      fontWeight: 900,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 20,
    },
    h4: {
      fontSize: 16,
    },
    h5: {
      fontSize: 14,
    },
    h6: {
      fontSize: 10,
    },

    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },

    button: {
      fontWeight: 'normal',
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
    MuiLink: {
      underline: 'always',
    },
  },
});

THEME.overrides = createOverrides(THEME);

export default THEME;
