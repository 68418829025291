import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  background: ${({ theme }) => theme.palette.gradient06.main};
  border: 1px solid ${({ theme }) => theme.palette.global02[500]};
  box-shadow: 0px 0px 12.3px rgba(116, 202, 255, 0.9);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(4)}px;
  width: 440px;
  min-height: 132px;
  max-width: 100%;
  display: flex;
`;

export const Badge = styled.div`
  position: absolute;
  top: -10px;
  left: 5%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: linear-gradient(
      268.93deg,
      #ff79ea 2.81%,
      #fd14d8 60.1%,
      #fd14d8 88.09%
    ),
    radial-gradient(90.71% 90.71% at 50% 22.86%, #fee96b 0%, #aa9002 70.06%);
  box-shadow: 0px 0px 26.34px 4.21px rgba(255, 255, 255, 0.3),
    2.82776px 2.82776px 2.82776px rgba(0, 0, 0, 0.25);
  transform: rotate(-9.69deg);

  text-shadow: 0px 0.746857px 2.24057px rgba(0, 0, 0, 0.3);
  font-size: 30px;
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 15px;
    margin-top: 9px;
  }
`;
