import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Guess } from 'types/guess.types';

const getGuesses = async (episodeId: string) => {
  const { data: response } = await apiService.getGuesses(episodeId);
  return response;
};

export const useGuesses = (
  episodeId = '',
  config: UseCommonQueryOptions<Guess[]> = { enabled: true },
) => {
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.guesses.byEpisode(episodeId),
    () => getGuesses(episodeId),
    {
      ...config,
      enabled: episodeId.length > 0 && config.enabled,
      staleTime: config?.staleTime ?? StaleTime.FIVE_MIN,
    },
  );

  const performerGuess = useCallback(
    (performerId: string) => {
      return data?.find((g) => g.performerId === performerId);
    },
    [data],
  );

  return {
    performerGuess,
    guesses: data,
    status,
    isLoading,
    error,
  };
};
