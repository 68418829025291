import styled from 'styled-components';

import GradientTypography from 'components/@common/GradientTypography';

export const RoundTitle = styled(GradientTypography).attrs({
  color: 'gradient01',
  component: 'h1',
})`
  filter: drop-shadow(0px 4px 52px rgba(0, 0, 0, 0.8));
  font-size: 24px;
  text-transform: uppercase;
`;

export const EyeImg = styled.img`
  padding: ${({ theme }) => theme.spacing(6, 0)};
  width: 175px;
`;
