import React from 'react';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics } from 'hooks';

import { LogoVtmGoWhite } from 'components/@icons';

import { LinkStyled } from './VtmGoButton.style';

interface Props {
  href: string;
  variant?: 'small' | 'long';
}

const VtmGoButton: React.FC<Props> = ({ children, variant, ...rest }) => {
  const { trackEvent } = useAnalytics();
  return (
    <LinkStyled
      {...rest}
      target="_blank"
      onClick={() => {
        trackEvent({
          eventName: EventName.ClickVtmGo,
          data: { screen_name: PageView.Home },
        });
      }}
    >
      {children}
      <LogoVtmGoWhite width={78} height={23} />
    </LinkStyled>
  );
};

export default VtmGoButton;
