import { Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import imgPrizes from 'assets/png/img-win-prizes.png';

import { PrizeImage, Wrap } from './PrizeCard.style';

interface Props {
  titleId: string;
}

const PrizeCard: React.VFC<Props> = ({ titleId }) => {
  return (
    <Wrap>
      <Typography variant="body1">
        <FormattedMessage
          id={titleId}
          values={{ b: (t: string) => <b>{t}</b> }}
        />
      </Typography>
      <PrizeImage src={imgPrizes} alt="win-prizes" />
    </Wrap>
  );
};

export default PrizeCard;
