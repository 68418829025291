import styled from 'styled-components';

export const BroadcastWrap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 396px;
  max-width: 396px;

  border-radius: ${({ theme }) => theme.shape.radius(2.5)}px;
  background: radial-gradient(
    155.85% 250.04% at 0% 41.67%,
    rgba(0, 15, 149, 0.9) 0%,
    rgba(0, 9, 36, 0.9) 100%
  );

  min-height: 115px;
  padding: ${({ theme }) => theme.spacing(4, 6)};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    max-width: 100%;

    border-radius: 0;
  }
`;
