import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { QueryKeys } from 'queries';
import { apiService } from 'services';
import { Lock } from 'types/guess.types';

const postLock = async () => {
  const { data: response } = await apiService.postLock();
  return response;
};

export const usePostLock = (
  episodeId: string,
  config: UseMutationOptions<Lock, AxiosError> = {},
) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading, error } = useMutation(() => postLock(), {
    ...config,
    onSuccess: async (data) => {
      const locksQueryKey = QueryKeys.locks.byEpisode(episodeId);
      await queryClient.cancelQueries(locksQueryKey);

      queryClient.setQueryData<Lock[]>(locksQueryKey, (old) => {
        return [
          ...(old?.filter((l) => l.roundNumber !== data.roundNumber) ?? []),
          data,
        ];
      });
    },
  });

  return {
    postLock: mutate,
    guess: data,
    isLoading,
    error,
  };
};
