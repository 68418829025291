import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';

export interface GradientProps extends Omit<TypographyProps, 'color'> {
  color: 'gradient01' | 'gradient02' | 'gradient07';
  fontWeight?: number | string;
  fontSize?: string;
  lineHeight?: number | string;
  glow?: boolean;
  shadow?: boolean;
}

export const TypographyStyled = styled(Typography)<{
  $color: GradientProps['color'];
  $fontWeight: GradientProps['fontWeight'];
  $fontSize: GradientProps['fontSize'];
  $lineHeight: GradientProps['lineHeight'];
  $glow: GradientProps['glow'];
  $shadow: GradientProps['shadow'];
}>`
  background: ${({ theme, $color }) => theme.palette[$color].main};
  background-clip: content-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-size: ${({ $fontSize }) => (!!$fontSize ? $fontSize : undefined)};
  line-height: ${({ $lineHeight }) => $lineHeight};
  text-transform: uppercase;

  ${({ $glow }) =>
    $glow &&
    css`
      filter: drop-shadow(0px 0px 11.8321px rgba(255, 255, 255, 0.6));
      line-height: 125%;
    `}

  ${({ $shadow }) =>
    $shadow &&
    css`
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      line-height: 125%;
    `}
`;

const GradientTypography: React.FC<GradientProps> = ({
  children,
  color = 'gradient01',
  fontWeight = 900,
  fontSize,
  lineHeight = 1.5,
  glow,
  shadow,
  ...rest
}) => (
  <TypographyStyled
    $color={color}
    $fontWeight={fontWeight}
    $fontSize={fontSize}
    $lineHeight={lineHeight}
    $glow={glow}
    $shadow={shadow}
    {...rest}
  >
    {children}
  </TypographyStyled>
);

export default GradientTypography;
