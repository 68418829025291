import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { usePrevious } from 'hooks';

import PointsItem from 'components/@common/PointsItem';

interface Props {
  roundScore?: number;
  totalScore?: number;
  position?: number;
}

const PointsCard: React.VFC<Props> = ({ roundScore, totalScore, position }) => {
  const prevTotalScore = usePrevious<number | undefined>(totalScore);
  const prevPosition = usePrevious<number | undefined>(position);

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      textAlign="center"
      maxWidth={400}
      mx="auto"
    >
      <Grid container justifyContent="center">
        {roundScore !== undefined && (
          <Grid item xs={4}>
            <PointsItem
              title={<FormattedMessage id="game.reveal.info.score.round" />}
              label={<FormattedMessage id="game.reveal.info.score.points" />}
              prefix="+"
              color="gradient07"
              value={roundScore}
              startValue={0}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <PointsItem
            title={<FormattedMessage id="game.reveal.info.score.total" />}
            label={<FormattedMessage id="game.reveal.info.score.points" />}
            color="gradient01"
            value={totalScore}
            startValue={prevTotalScore ?? totalScore}
          />
        </Grid>
        <Grid item xs={4}>
          <PointsItem
            title={<FormattedMessage id="game.reveal.info.position" />}
            label={<FormattedMessage id="game.reveal.info.position.flanders" />}
            color="gradient02"
            value={position}
            startValue={prevPosition ?? position}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PointsCard;
