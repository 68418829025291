import { getTime, isAfter } from 'date-fns';
import _maxBy from 'lodash/maxBy';
import _minBy from 'lodash/minBy';

import { Episode } from 'types/episode.types';

export const findLastEpisode = (episodes: Episode[]) => {
  // Check if an episode is live
  const lastEpisode = episodes?.find((e) => e.isLive);

  if (lastEpisode) {
    return lastEpisode;
  }

  // Check if episodes are not in history
  const nextEpisodes = episodes?.filter((e) =>
    isAfter(new Date(e.startDate), new Date()),
  );

  return _minBy(nextEpisodes, (e) => getTime(new Date(e.startDate)));
};
