import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedPlural } from 'react-intl';
import { FormattedMessage } from 'react-intl-phraseapp';

import { inIframe } from 'utils/window.utils';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useGuesses, useLocks, usePostGuess, usePostLock } from 'queries';
import { RoundState, VotesGameState } from 'types/game.types';

import GameCarousel from 'components/@carousel/GameCarousel';
import LightsBackground from 'components/@common/LightsBackground';
import PageContainer from 'components/@common/PageContainer';
import PageMargin from 'components/@common/PageMargin';
import RoundBonus from 'components/@game/RoundBonus';
import VoteButton from 'components/@game/VoteButton';

interface Props {
  gameState: VotesGameState;
}

const VoteState: React.VFC<Props> = ({ gameState }) => {
  const {
    performers,
    roundNumber,
    episodeId,
    roundState,
    votesClosedDate,
    votesOpenDate,
  } = gameState;

  const { trackEvent } = useAnalytics();

  const [activePerformer, setActivePerformer] = useState(performers[0]);

  const { roundLock } = useLocks(episodeId);
  const { postLock, isLoading: isLocking } = usePostLock(episodeId);

  const { guesses, performerGuess, isLoading } = useGuesses(episodeId);
  const { postGuess } = usePostGuess(episodeId);

  const currentRoundLock = roundLock(roundNumber);
  const locked = roundState === RoundState.VotesClosed || !!currentRoundLock;

  const handleGuess = (good: boolean) => {
    if (!locked) {
      trackEvent({
        eventName: good ? EventName.ClickVoteEcht : EventName.ClickVoteSlecht,
        data: {
          screen_name: PageView.RoundVoting.replace('{x}', `${roundNumber}`),
          actor_name: activePerformer.name,
          round_number: roundNumber,
        },
      });

      postGuess({ isReal: good, performerId: activePerformer.id });
    }
  };

  const guessesToGo = Math.max(performers.length - (guesses ?? []).length, 0);
  const activeGuess = performerGuess(activePerformer ? activePerformer.id : '');

  return (
    <>
      <PageContainer
        bgcolor={locked ? 'neutral03.400' : 'neutral02.500'}
        pt={inIframe() ? 22 : 10}
        pb={10}
      >
        <PageMargin>
          <RoundBonus
            roundNumber={roundNumber}
            startTime={new Date(votesOpenDate)}
            endTime={new Date(votesClosedDate)}
            finalScore={currentRoundLock?.lockedPoints}
            lockedTime={
              currentRoundLock
                ? new Date(currentRoundLock.lockDate)
                : locked
                ? new Date(votesClosedDate)
                : undefined
            }
          />
        </PageMargin>
      </PageContainer>
      <Box position="relative">
        <Box position="absolute" top={0} left={0} right={0}>
          <LightsBackground />
        </Box>
        <PageContainer pb={10}>
          {isLoading ? (
            <Box height={400} display="flex" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box mx="auto" maxWidth={450} pt={10} width="100%" mb={5}>
                <GameCarousel
                  performers={performers}
                  guesses={guesses}
                  onSlideChange={(idx, performer, prevIdx) => {
                    trackEvent({
                      eventName: EventName.ClickArrow,
                      data: {
                        screen_name: PageView.RoundVoting.replace(
                          '{x}',
                          `${roundNumber}`,
                        ),
                        arrow_direction: idx > prevIdx ? 'right' : 'left',
                      },
                    });

                    setActivePerformer(performer);
                  }}
                />
              </Box>
              <PageMargin>
                {!locked ? (
                  <Box>
                    <Box height={120}>
                      <VoteButton
                        variant="bad"
                        active={activeGuess ? !activeGuess.isReal : undefined}
                        onClick={() => handleGuess(false)}
                      />
                      <VoteButton
                        variant="good"
                        active={activeGuess ? activeGuess.isReal : undefined}
                        onClick={() => handleGuess(true)}
                      />
                    </Box>
                    <Box mt={4}>
                      <Typography variant="body2">
                        <FormattedPlural
                          value={guessesToGo}
                          one={
                            <FormattedMessage
                              id="game.vote.performers_to_guess.label.one"
                              values={{ b: (t: string) => <b>{t}</b> }}
                            />
                          }
                          other={
                            <FormattedMessage
                              id="game.vote.performers_to_guess.label.other"
                              values={{
                                count: guessesToGo,
                                b: (t: string) => <b>{t}</b>,
                              }}
                            />
                          }
                        />
                      </Typography>
                    </Box>
                    <Button
                      disabled={guessesToGo > 0 && !locked && !isLocking}
                      onClick={() => {
                        trackEvent({
                          eventName: EventName.ClickLock,
                          data: {
                            screen_name: PageView.RoundVoting.replace(
                              '{x}',
                              `${roundNumber}`,
                            ),

                            round_number: roundNumber,
                          },
                        });
                        postLock();
                      }}
                    >
                      {isLocking ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <FormattedMessage id="game.vote.lock.button" />
                      )}
                    </Button>
                  </Box>
                ) : (
                  <>
                    <Box mb={6}>
                      <CircularProgress />
                    </Box>
                    <Typography variant="body1" color="primary">
                      <b>
                        <FormattedMessage id="game.drop_out.loading.label" />
                      </b>
                    </Typography>
                  </>
                )}
              </PageMargin>
            </>
          )}
        </PageContainer>
      </Box>
    </>
  );
};

export default VoteState;
