import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useProgressStyles = makeStyles(() =>
  createStyles<string, { value: number; locked: boolean }>({
    '@global': {
      '@keyframes gradiantAnimation': {
        '0%': { backgroundPosition: '-200% 0%' },
        '100%': { backgroundPosition: '200% 0%' },
      },
    },
    root: {
      height: 30,
      borderRadius: 40,
      overflow: 'visible',
    },
    colorPrimary: {
      background:
        'linear-gradient(268.93deg, #5168B1 2.81%, #0F2361 33.45%, #0F2361 88.09%)',
    },
    bar1Determinate: {
      background:
        'linear-gradient(270deg, #FF79EA 0%, #FF14D9 50%, #FF79EA 100%)',
      minWidth: 110,
      width: ({ value }) => `${value}%`,
      backgroundSize: '200% 100% !important',
      backgroundRepeat: 'repeat-x !important',
      boxShadow: '0px 0px 10px rgba(253, 20, 216, 0.8)',
      borderRadius: 40,
      animation: '$gradiantAnimation 3s linear infinite',
      animationPlayState: ({ locked }) => (locked ? 'paused' : 'running'),
      transform: 'none !important',
    },
  }),
);

export const RoundImg = styled.img`
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  width: 100px;
`;

export const LockWrap = styled(Box)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, #001aff 0%, #1c2aa6 100%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
