import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

import imgHeaderDesktop from 'assets/jpeg/bg-header-desktop.jpg';
import imgHeaderMobile from 'assets/jpeg/bg-header-mobile.jpg';

export const HeaderWrap = styled(Box)`
  position: relative;
  background-image: url(${imgHeaderMobile});
  background-position: center center;
  background-size: cover;
  color: white;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background-image: url(${imgHeaderDesktop});
  }
`;

export const HeaderTitle = styled(Typography).attrs({ component: 'h1' })`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 64px;
  }
`;

export const HeaderDescription = styled(Typography)`
  font-weight: 600;
  line-height: 1.6;

  max-width: 550px;
`;

export const Gradients = styled.div`
  &::before {
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
    content: ' ';
    background: linear-gradient(
      0deg,
      rgba(1, 1, 80, 1) 23.04%,
      rgba(1, 1, 161, 0.33) 74.68%,
      rgba(1, 1, 161, 0) 100%
    );
  }
`;
