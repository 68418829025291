import { Box, Typography } from '@material-ui/core';
import React from 'react';
import CountUp from 'react-countup';
import { FormattedMessage } from 'react-intl-phraseapp';

import GradientTypography from 'components/@common/GradientTypography';

import { Badge, Wrap } from './StreakCard.style';

interface Props {
  score: number;
  streak: number;
  className?: string;
}
const StreakCard: React.VFC<Props> = ({
  score,
  streak,
  className,
  ...rest
}) => {
  return (
    <Wrap className={className} {...rest}>
      <Badge>
        {streak}
        <span>x</span>
      </Badge>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex={1}
      >
        <GradientTypography color="gradient02" variant="h4" shadow>
          <FormattedMessage id="game.reveal.streak.title" />
        </GradientTypography>

        <GradientTypography color="gradient02" fontSize="32px" shadow>
          <CountUp end={score} prefix="+" separator="." delay={0.5} />
        </GradientTypography>

        <Typography variant="body2">
          <FormattedMessage
            id="game.reveal.streak.label"
            values={{ count: streak }}
          />
        </Typography>
      </Box>
    </Wrap>
  );
};

export default StreakCard;
