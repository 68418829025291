import styled from 'styled-components';

import Performer from 'components/@common/Performer';
import RevealCard from 'components/@game/RevealCard';
import StreakCard from 'components/@game/StreakCard';

export const RevealCardStyled = styled(RevealCard)`
  margin-top: ${({ theme }) => theme.spacing(10)}px;
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
`;

export const StreakCardStyled = styled(StreakCard)`
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
`;

export const PerformerStyled = styled(Performer)`
  max-width: 200px;
`;
