import config from 'config';

export default {
  API: {
    aws_project_region: 'eu-west-1',
    aws_appsync_graphqlEndpoint: `https://${config.AWS.APPSYNC_ID}.appsync-api.eu-west-1.amazonaws.com/graphql`,
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: config.AWS.APPSYNC_API_KEY,
  },
};
