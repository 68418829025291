import { Routes } from 'pages/routes.constants';

import Home from './Home';

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default {
  component: Home,
  exact: true,
  path: Routes.Root,
};
