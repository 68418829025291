import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography).attrs({ variant: 'h3' })`
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing(9)}px;
`;

export const ListGradient = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 154px;
    background: ${({ theme }) => theme.palette.global02[500]};
    opacity: 0.4;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 154px;
    opacity: 0.2;
    background: ${({ theme }) => theme.palette.gradient05.main};
  }
`;
