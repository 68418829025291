import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { LeaderboardUser } from 'types/leaderboard.types';

const getLeaderboard = async (currentEpisode: boolean) => {
  const { data: response } = await apiService.getLeaderBoard(currentEpisode);
  return response;
};

export const useLeaderboard = (
  currentEpisode: boolean,
  config: UseCommonQueryOptions<LeaderboardUser[]> = {},
) => {
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.leaderboard.all(currentEpisode),
    () => getLeaderboard(currentEpisode),
    {
      ...config,
      cacheTime: config?.cacheTime ?? StaleTime.FIVE_MIN,
      staleTime: config?.staleTime ?? StaleTime.FIFTEEN_MIN,
    },
  );

  return {
    leaderboard: data,
    status,
    isLoading,
    error,
  };
};
