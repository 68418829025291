import { LeaderboardUser } from 'types/leaderboard.types';

import RestApiService from './';

export default {
  getLeaderboard(this: RestApiService, currentEpisode = false) {
    return this.api.get<LeaderboardUser[]>('/leaderboard', {
      params: { currentEpisode: currentEpisode ? 1 : 0 },
    });
  },
  getUserLeaderboard(
    this: RestApiService,
    userId: string,
    currentEpisode = false,
  ) {
    return this.api.get<LeaderboardUser[]>(`/users/${userId}/leaderboard`, {
      params: { currentEpisode: currentEpisode ? 1 : 0 },
      authEnabled: true,
    });
  },
};
