import { ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonImg = styled.img`
  object-fit: contain;
  width: 100%;
`;

export const ButtonWrap = styled(ButtonBase)<{ $scale: number }>`
  width: 160px;
  transform: scale(${({ $scale }) => $scale});
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(${({ $scale }) => $scale + 0.1});
  }
`;
