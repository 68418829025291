import { Box } from '@material-ui/core';
import { FC, useEffect } from 'react';

import { findLastEpisode } from 'utils/episode.utils';

import { PageView } from 'constants/analytics';
import { useAnalytics, useAuth } from 'hooks';
import { useEpisodes } from 'queries';

import PageContainer from 'components/@common/PageContainer';
import PageMargin from 'components/@common/PageMargin';
import VideoGallery from 'components/@common/VideoGallery';
import CtaSection from 'components/@home/CtaSection';
import Episodes from 'components/@home/Episodes';
import Header from 'components/@home/Header';
import InfoHowToPlay from 'components/@home/InfoHowToPlay';
import InfoLeaderBoard from 'components/@home/InfoLeaderBoard';
import InfoPrizes from 'components/@home/InfoPrizes';
import VtmGoBanner from 'components/@home/VtmGoBanner';

const SHOW_LEADERBOARD = true;
const SHOW_VTMGO = true;
const SHOW_EPISODES = true;

const Home: FC = () => {
  const { trackPageView } = useAnalytics();
  const { isLoggedIn } = useAuth();
  const { episodes, isLoading } = useEpisodes();
  const currentEpisode = findLastEpisode(episodes ?? []);

  useEffect(() => {
    trackPageView({ path: PageView.Home });
  }, [trackPageView]);

  return (
    <>
      <Header
        isLive={currentEpisode?.isLive}
        liveDate={
          currentEpisode ? new Date(currentEpisode.startDate) : undefined
        }
        loading={isLoading}
      />
      <PageContainer>
        <PageMargin>
          <CtaSection
            isLive={currentEpisode?.isLive}
            liveDate={
              currentEpisode ? new Date(currentEpisode.startDate) : undefined
            }
            loading={isLoading}
          />
          {/* Info items */}
          <Box
            display="flex"
            flexWrap={{ xs: 'wrap', sm: undefined }}
            justifyContent={{ xs: 'center', sm: 'space-evenly' }}
            alignItems="flex-start"
            mt={22}
            mb={10}
            order={{ xs: 1, sm: 1 }}
          >
            <Box
              flexBasis={{ xs: '100%', sm: '30%' }}
              flexGrow={0}
              mt={{ xs: 11, sm: 0 }}
            >
              <InfoPrizes />
            </Box>
            <Box
              flexBasis={{ xs: '100%', sm: '30%' }}
              flexGrow={0}
              mt={{ xs: 11, sm: 0 }}
              order={{ xs: 4, sm: 2 }}
            >
              <InfoHowToPlay />
            </Box>
            {SHOW_LEADERBOARD && isLoggedIn && (
              <Box
                flexBasis={{ xs: '100%', sm: '30%' }}
                flexGrow={0}
                mt={{ xs: 11, sm: 0 }}
                order={{ xs: 2, sm: 3 }}
              >
                <InfoLeaderBoard />
              </Box>
            )}
            {SHOW_VTMGO && (
              <Box
                flexBasis="100%"
                mt={{ xs: 12, sm: 24 }}
                order={{ xs: 3, sm: 4 }}
              >
                <VtmGoBanner />
              </Box>
            )}
          </Box>
        </PageMargin>
        <Box my={5} width="100%">
          <VideoGallery id="5237906" />
        </Box>
        {SHOW_EPISODES && (
          <Box mb={10} width="100%">
            <Episodes />
          </Box>
        )}
      </PageContainer>
    </>
  );
};

export default Home;
