import { Box } from '@material-ui/core';
import React from 'react';

import imgHowToPlay from 'assets/png/img-how-to-play.png';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics, useIntl } from 'hooks';

import InfoItem from '../InfoItem';

const InfoHowToPlay: React.VFC = () => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  return (
    <InfoItem
      title={intl.formatMessage({
        id: 'home.items.how_to_play.title',
      })}
      description={intl.formatMessage({
        id: 'home.items.how_to_play.description',
      })}
      linkText={intl.formatMessage({
        id: 'home.items.how_to_play.link_text',
      })}
      href="https://vtm.be/i-can-see-your-voice/wedstrijdreglement-i-can-see-your-voice"
      onClick={() => {
        trackEvent({
          eventName: EventName.ClickTutorial,
          data: { screen_name: PageView.Home },
        });
      }}
    >
      <Box width="100%" clone>
        <img src={imgHowToPlay} alt="how-to-play" />
      </Box>
    </InfoItem>
  );
};

export default InfoHowToPlay;
