import { DateString } from './general.types';

export type Performer = {
  id: string;
  name: string;
  imageUrl: string;
  isRevealed: boolean;
  isReal?: boolean;
};

export type GameStateBase = {
  roundNumber: number;
  roundType: string;
  performers: Performer[];
  episodeId: string;
};

export type VotesGameState = GameStateBase & {
  roundState: RoundState.VotesOpen | RoundState.VotesClosed;
  performers: Performer[];
  votesOpenDate: DateString;
  votesClosedDate: DateString;
};

export type RevealGameState = GameStateBase & {
  roundState: RoundState.Reveal;
  revealedPerformerId: Performer['id'];
};

export type PreRoundGameState = GameStateBase & {
  roundState: RoundState.PreRound;
};

export type DropOutGameState = GameStateBase & {
  roundState: RoundState.DropOut;
  revealedPerformerId: Performer['id'];
};

export type PreEpisodeGameState = GameStateBase & {
  roundState: RoundState.PreEpisode;
};

export type GameState =
  | PreEpisodeGameState
  | PreRoundGameState
  | VotesGameState
  | DropOutGameState
  | RevealGameState;

export enum RoundState {
  PreEpisode = 'PRE_EPISODE',
  PreRound = 'START',
  VotesOpen = 'VOTING_START',
  VotesClosed = 'VOTING_LOCKED',
  DropOut = 'DROP_OUT',
  Reveal = 'REVEAL',
}
