import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from 'hooks';
import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { LeaderboardUser } from 'types/leaderboard.types';

const getUserLeaderBoard = async (userId: string, currentEpisode: boolean) => {
  const { data: response } = await apiService.getUserLeaderBoard(
    userId,
    currentEpisode,
  );
  return response;
};

export const useUserLeaderboard = (
  currentEpisode: boolean,
  config: UseCommonQueryOptions<LeaderboardUser[]> = { enabled: true },
) => {
  const { userId } = useAuth();
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.leaderboard.byUser(userId ?? '', currentEpisode),
    // We can disable it because the query isn't enabled without a valid userId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getUserLeaderBoard(userId!, currentEpisode),
    {
      ...config,
      enabled: !!userId && config.enabled,
      cacheTime: config?.cacheTime ?? StaleTime.FOREVER,
      staleTime: config?.staleTime ?? StaleTime.MINUTE,
    },
  );

  const user = useMemo(
    () => data?.find((u) => u.id === userId),
    [data, userId],
  );

  return {
    userLeaderboard: data,
    user,
    status,
    isLoading,
    error,
  };
};
