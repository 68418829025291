import { ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

import imgVtmGoBannerDesktop from 'assets/png/img-vtm-go-banner-desktop.png';
import imgVtmGoBannerMobile from 'assets/png/img-vtm-go-banner-mobile.png';

export const Wrap = styled(ButtonBase)`
  width: 100%;
  max-width: 828px;

  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${({ theme }) => theme.spacing(9)}px;
  padding-bottom: ${({ theme }) => theme.spacing(9)}px;
  color: ${({ theme }) => theme.palette.neutral01[500]};

  background-image: url(${imgVtmGoBannerMobile});
  background-position: top center;
  background-size: cover;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    background-image: url(${imgVtmGoBannerDesktop});
    background-position: center center;
  }
`;
