import _orderBy from 'lodash/orderBy';
import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import { Episode } from 'types/episode.types';

const getEpisodes = async () => {
  const { data: response } = await apiService.getEpisodes();
  return response;
};

export const prefetchEpisodes = async () => {
  try {
    await queryClient.prefetchQuery(QueryKeys.episode.all(), () =>
      getEpisodes(),
    );
  } catch {}
};

export const useEpisodes = (config: UseCommonQueryOptions<Episode[]> = {}) => {
  const { data, status, isLoading, error, refetch } = useQuery(
    QueryKeys.episode.all(),
    () => getEpisodes(),
    {
      ...config,
      cacheTime: config?.cacheTime ?? StaleTime.FOREVER,
      staleTime: config?.staleTime ?? StaleTime.ONE_HOUR,
    },
  );

  return {
    episodes: data
      ? _orderBy(data, (e) => e.episodeNumber, ['desc'])
      : undefined,
    status,
    isLoading,
    refetch,
    error,
  };
};
