import {
  Box,
  CircularProgress,
  Collapse,
  Link,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { inIframe } from 'utils/window.utils';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useGuesses, useScoreBreakdown, useUserLeaderboard } from 'queries';
import { historyService } from 'services';
import {
  DropOutGameState,
  RevealGameState,
  RoundState,
} from 'types/game.types';

import { Routes } from 'pages/routes.constants';

import LightsBackground from 'components/@common/LightsBackground';
import PageContainer from 'components/@common/PageContainer';
import PageMargin from 'components/@common/PageMargin';
import EyeAnimation from 'components/@game/EyeAnimation';
import PointsCard from 'components/@game/PointsCard';

import {
  PerformerStyled,
  RevealCardStyled,
  StreakCardStyled,
} from './RevealState.style';

interface Props {
  gameState: DropOutGameState | RevealGameState;
}

const RevealState: React.VFC<Props> = ({ gameState }) => {
  const { trackEvent } = useAnalytics();
  const { performerGuess } = useGuesses(gameState.episodeId);
  const [showIsReal, setShowIsReal] = useState(false);

  const performer = gameState.performers.find(
    (p) => p.id === gameState.revealedPerformerId,
  );

  const { user } = useUserLeaderboard(true, { refetchOnReconnect: true });

  const { scoreForPerformer } = useScoreBreakdown({ refetchOnReconnect: true });
  const guess = performerGuess(gameState.revealedPerformerId);
  const isReal =
    gameState.roundState === RoundState.Reveal ? performer?.isReal : undefined;

  const userScore =
    performer && scoreForPerformer(gameState.episodeId, performer.id);

  useEffect(() => {
    if (userScore && userScore.streak > 1) {
      trackEvent({
        eventName: EventName.StreakBonus,
        data: {
          screen_name: PageView.RoundVoting.replace(
            '{x}',
            `${gameState.roundNumber}`,
          ),
        },
      });
    }
  }, [userScore, trackEvent, gameState.roundNumber]);

  return (
    <>
      <Box position="absolute" top={0} left={0} right={0}>
        <LightsBackground />
      </Box>
      <PageContainer pt={inIframe() ? 22 : 10}>
        <Box
          zIndex={10}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width="100%"
        >
          {performer && (
            <PerformerStyled
              performer={performer}
              isReal={showIsReal ? undefined : guess?.isReal}
            />
          )}
          <Box position="relative" maxWidth="100%">
            <Box
              position="absolute"
              left={0}
              right={0}
              top={-3}
              display="flex"
              justifyContent="center"
              zIndex={10}
            >
              <EyeAnimation
                isReal={isReal}
                onAnimationComplete={() => setShowIsReal(true)}
              />
            </Box>

            <Collapse in={showIsReal} timeout={600}>
              {userScore && (
                <Box m={4}>
                  <RevealCardStyled
                    score={userScore.lockedPoints}
                    isCorrect={userScore.isCorrect}
                  />

                  {userScore.streak >= 2 && (
                    <StreakCardStyled
                      score={userScore.streakPoints}
                      streak={userScore.streak}
                    />
                  )}
                </Box>
              )}
            </Collapse>
          </Box>
          <Box
            mt={showIsReal && userScore ? 0 : 30}
            style={{ transition: 'margin 1000ms ease' }}
            width="100%"
          >
            <PageMargin>
              <PointsCard
                roundScore={
                  userScore && showIsReal
                    ? userScore.lockedPoints + userScore.streakPoints
                    : undefined
                }
                totalScore={user?.score}
                position={user?.position}
              />
              <Box mt={6}>
                {userScore && showIsReal && (
                  <Link
                    component="button"
                    onClick={() => historyService.push(Routes.Leaderboard)}
                  >
                    <FormattedMessage id="game.reveal.leaderboard.button" />
                  </Link>
                )}
              </Box>

              {gameState.roundNumber < 7 && showIsReal && (
                <Box my={10}>
                  <Box mb={6}>
                    <CircularProgress />
                  </Box>
                  <Typography variant="body1" color="primary">
                    <b>
                      <FormattedMessage
                        id={
                          gameState.roundNumber === 6
                            ? 'game.reveal.final.loading.label'
                            : 'game.reveal.loading.label'
                        }
                      />
                    </b>
                  </Typography>
                </Box>
              )}
            </PageMargin>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};

export default RevealState;
