import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoLock = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 9 11" fill="none" {...props}>
    <path
      d="M4.29246 8.33965C4.57707 8.33965 4.85002 8.22982 5.05127 8.03432C5.25252 7.83882 5.36558 7.57367 5.36558 7.29719C5.36558 6.71863 4.88268 6.25473 4.29246 6.25473C4.00786 6.25473 3.73491 6.36456 3.53366 6.56006C3.33241 6.75556 3.21935 7.02071 3.21935 7.29719C3.21935 7.57367 3.33241 7.83882 3.53366 8.03432C3.73491 8.22982 4.00786 8.33965 4.29246 8.33965ZM7.51181 3.6486C7.79642 3.6486 8.06937 3.75843 8.27062 3.95392C8.47187 4.14942 8.58493 4.41457 8.58493 4.69105V9.90333C8.58493 10.1798 8.47187 10.445 8.27062 10.6405C8.06937 10.836 7.79642 10.9458 7.51181 10.9458H1.07312C0.788508 10.9458 0.515557 10.836 0.314308 10.6405C0.11306 10.445 0 10.1798 0 9.90333V4.69105C0 4.11249 0.482902 3.6486 1.07312 3.6486H1.60967V2.60614C1.60967 1.91495 1.89232 1.25207 2.39545 0.763321C2.89857 0.274575 3.58094 0 4.29246 0C4.64477 0 4.99363 0.0674098 5.31912 0.198381C5.64462 0.329351 5.94036 0.521318 6.18948 0.763321C6.4386 1.00532 6.63622 1.29262 6.77104 1.60881C6.90586 1.925 6.97526 2.2639 6.97526 2.60614V3.6486H7.51181ZM4.29246 1.04246C3.86555 1.04246 3.45613 1.2072 3.15425 1.50045C2.85238 1.7937 2.68279 2.19142 2.68279 2.60614V3.6486H5.90214V2.60614C5.90214 2.19142 5.73255 1.7937 5.43068 1.50045C5.1288 1.2072 4.71938 1.04246 4.29246 1.04246Z"
      fill="white"
    />
  </svg>
);

const Memo = memo(SvgIcoLock);
export default Memo;
