/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import game from './game';
import home from './home';
import leaderboard from './leaderboard';
import loginCallback from './login-callback';
import { Route } from './routes.types';

export default [home, loginCallback, game, leaderboard] as Route[];
