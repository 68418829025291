import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M3.28581 10.4286L3.28581 7.57147L9.71438 7.57147L7.21438 5.07147L8.94295 3.3429L14.6001 9.00004L8.94295 14.6572L7.21438 12.9286L9.71438 10.4286L3.28581 10.4286Z"
      fill="white"
    />
  </svg>
);

const Memo = memo(SvgIcoArrowRight);
export default Memo;
