import { Box, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { roundImage } from 'utils/game.utils';

import { Black } from 'components/@common/Black';
import { IcoAutoLock, IcoLock } from 'components/@icons';

import { LockWrap, RoundImg, useProgressStyles } from './RoundBonus.style';

interface Props {
  locked?: boolean;
  minScore: number;
  maxScore: number;
  score: number;
  roundNumber: number;
}

const RoundBonus: React.VFC<Props> = ({
  score,
  maxScore,
  minScore,
  locked = false,
  roundNumber,
}) => {
  const isAutoLock = locked && score <= minScore;
  const progress = 25 + (score / maxScore) * 100 * 0.8;

  const progressStyles = useProgressStyles({ value: progress, locked });

  return (
    <Box textAlign="center" width="100%" maxWidth={450}>
      {locked && isAutoLock && <IcoAutoLock fontSize={50} />}
      <Box px={4} clone>
        <Typography variant="h3">
          <Black>
            <FormattedMessage
              id={
                locked
                  ? !isAutoLock
                    ? 'game.vote.bonus.user_locked.title'
                    : 'game.vote.bonus.auto_locked.title'
                  : 'game.vote.bonus.title'
              }
            />
          </Black>
        </Typography>
      </Box>
      {(!locked || isAutoLock) && (
        <Box
          component="span"
          display="flex"
          justifyContent="center"
          mt={4}
          height={20}
        >
          {locked ? (
            <FormattedMessage
              id="game.vote.bonus.locked.description"
              values={{ b: (t: string) => <b>{t}</b> }}
            />
          ) : (
            <>
              <IcoLock style={{ marginRight: 4 }} />
              <Typography variant="h6" component="span">
                <FormattedMessage
                  id="game.vote.bonus.description"
                  values={{ b: (t: string) => <b>{t}</b> }}
                />
              </Typography>
            </>
          )}
        </Box>
      )}
      <Box position="relative" mt={6}>
        <Box ml={10} position="relative">
          <LinearProgress
            classes={progressStyles}
            value={progress}
            variant="determinate"
          />
          {locked && (
            <LockWrap
              right={`calc(min(${100 - progress}%, 100% - 110px) + 3px)`}
            >
              <IcoLock />
            </LockWrap>
          )}
        </Box>
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={70}
          display="flex"
          alignItems="center"
        >
          <Typography variant="h3">
            <Black>+{score}</Black>
          </Typography>
        </Box>
        <RoundImg src={roundImage(roundNumber)} alt="round 1" />
      </Box>
    </Box>
  );
};

export default RoundBonus;
