import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import CountUp from 'react-countup';

import GradientTypography from 'components/@common/GradientTypography';

import { GradientProps } from '../GradientTypography/GradientTypography';
import { Label, Title } from './PointsItem.style';

interface ItemProps {
  title: React.ReactNode;
  value?: number;
  startValue?: number;
  prefix?: string;
  label: React.ReactNode;
  color: GradientProps['color'];
}
const PointsItem: React.VFC<ItemProps> = ({
  title,
  value,
  startValue = value,
  prefix = '',
  label,
  color,
}) => {
  return (
    <Box>
      <Title>{title}</Title>
      {value !== undefined ? (
        <GradientTypography variant="h2" color={color} glow>
          <CountUp
            start={startValue}
            end={value}
            prefix={prefix}
            separator="."
            preserveValue
          />
        </GradientTypography>
      ) : (
        <Box my={1}>
          <CircularProgress size={16} />
        </Box>
      )}
      <Label>{label}</Label>
    </Box>
  );
};

export default PointsItem;
