import { Box, Typography } from '@material-ui/core';
import React from 'react';
import CountUp from 'react-countup';
import { FormattedMessage } from 'react-intl-phraseapp';

import GradientTypography from 'components/@common/GradientTypography';

import { Wrap } from './RevealCard.style';

interface Props {
  score: number;
  isCorrect: boolean;
  className?: string;
}

const RevealCard: React.VFC<Props> = ({
  score,
  isCorrect,
  className,
  ...rest
}) => {
  return (
    <Wrap className={className} {...rest}>
      <Box mt={8}>
        <GradientTypography color="gradient02" fontSize="32px" shadow>
          {!!isCorrect ? <CountUp end={score} prefix="+" separator="." /> : 0}
        </GradientTypography>

        <Typography variant="body2">
          <FormattedMessage
            id={`game.reveal.user.${isCorrect ? 'correct' : 'wrong'}`}
            values={{ b: (t: string) => <b>{t}</b> }}
          />
        </Typography>
      </Box>
    </Wrap>
  );
};

export default RevealCard;
