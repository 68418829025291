import { Routes } from 'pages/routes.constants';

import Leaderboard from './Leaderboard';

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default {
  component: Leaderboard,
  exact: true,
  path: Routes.Leaderboard,
};
