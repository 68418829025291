import { Box } from '@material-ui/core';
import React from 'react';

import imgPrizes from 'assets/png/img-win-prizes.png';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics, useIntl } from 'hooks';

import InfoItem from '../InfoItem';

const InfoPrizes: React.VFC = () => {
  const { trackEvent } = useAnalytics();
  const intl = useIntl();

  return (
    <InfoItem
      title={intl.formatMessage({ id: 'home.items.win_prizes.title' })}
      description={intl.formatMessage({
        id: 'home.items.win_prizes.description',
      })}
      linkText={intl.formatMessage({
        id: 'home.items.win_prizes.link_text',
      })}
      href="https://vtm.be/i-can-see-your-voice/prijzen"
      onClick={() => {
        trackEvent({
          eventName: EventName.ClickPrizes,
          data: { screen_name: PageView.Home },
        });
      }}
    >
      <Box width="100%" mt={8} clone>
        <img src={imgPrizes} alt="win-prizes" />
      </Box>
    </InfoItem>
  );
};

export default InfoPrizes;
