import {
  Box,
  Dialog as MUIDialog,
  DialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { IcoClose } from 'components/@icons';

import { Black } from '../Black';

interface DialogTitleProps {
  onClose: () => void;
}

const DialogTitle: React.FC<DialogTitleProps> = ({ onClose, children }) => {
  return (
    <MuiDialogTitle disableTypography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">
          <Black>{children}</Black>
        </Typography>
        {onClose ? (
          <IconButton onClick={onClose} size="small" title="Sluiten">
            <IcoClose width={32} height={32} />
          </IconButton>
        ) : null}
      </Box>
    </MuiDialogTitle>
  );
};

interface Props extends DialogProps {
  onClose: () => void;
  open: boolean;
  title: string;
}

export const DialogStyled = styled(MUIDialog)<{
  $top: number;
  $maxHeight: number;
}>`
  .MuiDialog-paper {
    min-width: 100%;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      min-width: 440px;
    }

    border-radius: ${({ theme }) => theme.shape.radius(2.5)}px;
    margin-top: ${({ $top }) => $top}px;
    margin-bottom: auto;
    max-height: ${({ $maxHeight }) =>
      $maxHeight <= 0 ? '75vh' : `${$maxHeight}px`};
  }
`;

const Dialog: React.FC<Props> = ({
  onClose,
  open,
  title,
  children,
  ...rest
}) => {
  const [screenSpace, setScreenSpace] = useState({ top: 90, maxHeight: 0 });

  useEffect(() => {
    const parentIframe = window.parentIFrame;

    if (open && parentIframe) {
      parentIframe.getPageInfo(({ scrollTop, iframeHeight }) => {
        const top = Math.max(scrollTop, 0) + 90;
        setScreenSpace({
          top,
          maxHeight: iframeHeight - top - 90,
        });
        parentIframe.getPageInfo(false);
      });
    }
    return () => {
      if (parentIframe) {
        parentIframe.getPageInfo(false);
      }
    };
  }, [open]);

  return (
    <DialogStyled
      onClose={onClose}
      open={open}
      keepMounted={false}
      {...rest}
      scroll="paper"
      $top={screenSpace.top}
      $maxHeight={screenSpace.maxHeight}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </DialogStyled>
  );
};

export default Dialog;
