import { Box } from '@material-ui/core';
import React from 'react';

import { LogoVtmGoWhite } from 'components/@icons';

import { LinkStyled } from './VtmGoButtonBig.style';

interface Props {
  href?: string;
  variant?: 'small' | 'long';
}

const VtmGoButtonBig: React.FC<Props> = ({ children, variant, ...rest }) => {
  return (
    <LinkStyled {...rest} target="_blank">
      {children && <Box mr={2}>{children}</Box>}
      <LogoVtmGoWhite width={78} height={23} />
    </LinkStyled>
  );
};

export default VtmGoButtonBig;
