import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCrown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 22 18" fill="none" {...props}>
    <path
      d="M2.75 12.6L0 3.6L6.512 7.2L11 0L15.488 7.2L22 3.6L19.25 12.6H2.75ZM2.75 14.4V18H19.25V14.4H2.75Z"
      fill="currentColor"
    />
  </svg>
);

const Memo = memo(SvgIcoCrown);
export default Memo;
