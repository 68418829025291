import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { differenceInMilliseconds } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics, useAuth } from 'hooks';
import { historyService } from 'services';

import { Routes } from 'pages/routes.constants';

import LiveBadgeBig from 'components/@common/LiveBadgeBig';
import WeightTypography from 'components/@common/WeightTypography';

import Countdown from '../Countdown';

interface Props {
  isLive?: boolean;
  isDisabled?: boolean;
  liveDate?: Date;
  loading?: boolean;
  hideButton?: boolean;
}

const CtaSection: React.VFC<Props> = ({
  isLive,
  isDisabled,
  liveDate,
  loading,
  hideButton = false,
}) => {
  const [difference, setDifference] = useState<number | undefined>(
    liveDate ? differenceInMilliseconds(liveDate, new Date()) : undefined,
  );
  const { trackEvent } = useAnalytics();
  const { login, isLoggedIn, isLoading } = useAuth();

  useEffect(() => {
    // Trigger re-render every second
    if (liveDate) {
      setDifference(differenceInMilliseconds(liveDate, new Date()));

      const interval = setInterval(() => {
        setDifference(differenceInMilliseconds(liveDate, new Date()));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [liveDate]);

  const onButtonClick = () => {
    if (isLoggedIn) {
      trackEvent({
        eventName: EventName.ClickPlay,
        data: { screen_name: PageView.Home },
      });
      historyService.push(Routes.Game);
    } else {
      login(Routes.Game);
    }
  };

  const getRemainingTime = () => {
    let seconds =
      difference && difference > 0 ? Math.floor(difference / 1000) : 0;
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours %= 24;
    minutes %= 60;
    seconds %= 60;

    return { seconds, minutes, hours, days };
  };

  const { days, hours, minutes, seconds } = getRemainingTime();

  if (!loading && liveDate === undefined) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={9}>
      {!isLive ? (
        <>
          <Box mb={4}>
            <WeightTypography>
              <FormattedMessage id="home.cta.next_episode.label" />
            </WeightTypography>
          </Box>
          {liveDate ? (
            <Countdown
              days={days}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          ) : (
            <CircularProgress />
          )}
        </>
      ) : (
        <>
          <Box mb={5}>
            <LiveBadgeBig />
          </Box>
          <Box maxWidth="275px" textAlign="center">
            <Typography>
              <FormattedMessage id="home.cta.show_started.label" />
            </Typography>
          </Box>
        </>
      )}
      {!hideButton && (
        <Button onClick={onButtonClick} disabled={isDisabled && !isLoading}>
          {isLoading ? (
            <CircularProgress color="inherit" />
          ) : (
            <FormattedMessage id="home.cta.button.label" />
          )}
        </Button>
      )}
    </Box>
  );
};

export default CtaSection;
