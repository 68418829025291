import { Routes } from 'pages/routes.constants';

import Game from './Game';

/**
 * custom params
 * - authenticated: if true > renders authenticated route > else > renders public route
 */
export default {
  component: Game,
  exact: true,
  path: Routes.Game,
};
