import styled from 'styled-components';

import GradientTypography from 'components/@common/GradientTypography';

export const RoundTitle = styled(GradientTypography).attrs({
  color: 'gradient01',
  component: 'h1',
})`
  filter: drop-shadow(0px 4px 52px rgba(0, 0, 0, 0.8));
  font-size: 24px;
`;

export const EyeImg = styled.img`
  padding: ${({ theme }) => theme.spacing(1)}px;
  width: 112px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;
