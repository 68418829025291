import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { correctRoundNumber, roundImage } from 'utils/game.utils';
import { inIframe } from 'utils/window.utils';

import { useIntl } from 'hooks';

import LightsBackground from 'components/@common/LightsBackground';
import PageContainer from 'components/@common/PageContainer';
import PageMargin from 'components/@common/PageMargin';
import GameTip from 'components/@game/GameTip';

import { EyeImg, RoundTitle } from './PreRoundState.style';

interface Props {
  round: number;
}

const PreRoundState: React.VFC<Props> = ({ round }) => {
  const intl = useIntl();
  return (
    <Box position="relative">
      <Box position="absolute" top={0} left={0} right={0}>
        <LightsBackground />
      </Box>
      <PageContainer pt={inIframe() ? 22 : 10} pb={10}>
        <PageMargin>
          <RoundTitle>
            <FormattedMessage
              id={`game.pre_round.round_${correctRoundNumber(round)}.title`}
            />
          </RoundTitle>
          <EyeImg
            src={roundImage(round)}
            alt={intl.formatMessage({
              id: `game.pre_round.round_${correctRoundNumber(round)}.title`,
            })}
          />
          <Box pb={15} clone>
            <Typography variant="body1">
              <FormattedMessage
                id={`game.pre_episode.round_${correctRoundNumber(round)}.text`}
              />
            </Typography>
          </Box>
          <GameTip />
          <Box mt={15} mb={6}>
            <CircularProgress />
          </Box>
          <Typography variant="body1" color="primary">
            <b>
              <FormattedMessage id="game.pre_round.loading.label" />
            </b>
          </Typography>
        </PageMargin>
      </PageContainer>
    </Box>
  );
};

export default PreRoundState;
