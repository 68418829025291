import { Box } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import GradientTypography from 'components/@common/GradientTypography';
import WeightTypography from 'components/@common/WeightTypography';

interface Props {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const Countdown: React.VFC<Props> = ({ days, hours, minutes, seconds }) => {
  return (
    <Box mx="auto">
      <Box
        display="inline-flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={60}
        mr={8}
      >
        <GradientTypography
          color="gradient01"
          lineHeight={1.2}
          variant="h1"
          // @ts-ignore
          component="p"
        >
          {days < 10 && 0}
          {days}
        </GradientTypography>
        <Box mt={2}>
          <WeightTypography fontWeight={900}>
            <FormattedMessage id="home.countdown.days.label" />
          </WeightTypography>
        </Box>
      </Box>
      <Box
        display="inline-flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={60}
        mr={8}
      >
        <GradientTypography
          color="gradient01"
          lineHeight={1.2}
          variant="h1"
          // @ts-ignore
          component="p"
        >
          {hours < 10 && 0}
          {hours}
        </GradientTypography>
        <Box mt={2}>
          <WeightTypography fontWeight={900}>
            <FormattedMessage id="home.countdown.hour.label" />
          </WeightTypography>
        </Box>
      </Box>
      <Box
        display="inline-flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={60}
        mr={8}
      >
        <GradientTypography
          color="gradient01"
          lineHeight={1.2}
          variant="h1"
          // @ts-ignore
          component="p"
        >
          {minutes < 10 && 0}
          {minutes}
        </GradientTypography>
        <Box mt={2}>
          <WeightTypography fontWeight={900}>
            <FormattedMessage id="home.countdown.min.label" />
          </WeightTypography>
        </Box>
      </Box>
      <Box
        display="inline-flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={60}
      >
        <GradientTypography
          color="gradient01"
          lineHeight={1.2}
          variant="h1"
          // @ts-ignore
          component="p"
        >
          {seconds < 10 && 0}
          {seconds}
        </GradientTypography>
        <Box mt={2}>
          <WeightTypography fontWeight={900}>
            <FormattedMessage id="home.countdown.sec.label" />
          </WeightTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default Countdown;
