import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics, useIntl } from 'hooks';
import { useUserLeaderboard } from 'queries';
import { historyService } from 'services';

import { Routes } from 'pages/routes.constants';

import { Black } from 'components/@common/Black';

import InfoItem from '../InfoItem';
import { BlueText, GradientTypographyStyled } from './InfoLeaderBoard.style';

const InfoLeaderBoard: React.VFC = () => {
  const { trackEvent } = useAnalytics();
  const { user, isLoading } = useUserLeaderboard(false);
  const intl = useIntl();

  return (
    <InfoItem
      title={intl.formatMessage({
        id: 'home.items.leaderboard.title',
      })}
      linkText={intl.formatMessage({
        id: 'home.items.leaderboard.link_text',
      })}
      onClick={() => {
        trackEvent({
          eventName: EventName.ClickLeaderboard,
          data: { screen_name: PageView.Home },
        });

        historyService.push(Routes.Leaderboard);
      }}
    >
      <Box mb={4} mt={9}>
        <Typography variant="h5" component="p">
          <Black>
            <FormattedMessage id="home.items.leaderboard.this_moment" />
          </Black>
        </Typography>
        <Box mt={3} mb={1} style={{ textTransform: 'uppercase' }}>
          <Typography variant="h5" component="p">
            <Black>
              <FormattedMessage id="home.items.leaderboard.place" />
            </Black>
          </Typography>
        </Box>
        {!user && isLoading && <CircularProgress size={24} />}
        {user && (
          <GradientTypographyStyled
            variant="h1"
            /* @ts-ignore */
            component="p"
            color="gradient02"
          >
            <Black>{user.position}</Black>
          </GradientTypographyStyled>
        )}
        <BlueText variant="body2">
          <FormattedMessage id="home.items.leaderboard.in_flanders" />
        </BlueText>
      </Box>
    </InfoItem>
  );
};

export default InfoLeaderBoard;
