import { Container, Link, Typography } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Routes } from 'pages/routes.constants';

const NotFound: FC = () => (
  <Container>
    <Typography variant="h1" gutterBottom>
      404
    </Typography>
    <Link component={RouterLink} to={Routes.Root}>
      Go to {Routes.Root}
    </Link>
  </Container>
);

export default NotFound;
