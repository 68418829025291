import type { ScoreBreakdown } from 'types/score.types';

import RestApiService from './';

export default {
  getScoreBreakdown(this: RestApiService, userId: string) {
    return this.api.get<ScoreBreakdown>(`users/${userId}/score-breakdown`, {
      authEnabled: true,
    });
  },
};
