import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import { GameState } from 'types/game.types';

const getGameState = async () => {
  const { data: response } = await apiService.getGameState();
  return response;
};

export const prefetchGameState = async () => {
  try {
    await queryClient.prefetchQuery(QueryKeys.gameState.default(), () =>
      getGameState(),
    );
  } catch {}
};

export const useGameState = (config: UseCommonQueryOptions<GameState> = {}) => {
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.gameState.default(),
    () => getGameState(),
    {
      ...config,
      refetchOnReconnect: true,
      refetchIntervalInBackground: true,
      refetchInterval: 1000,
      cacheTime: config?.cacheTime ?? StaleTime.ONE_HOUR,
      staleTime: config?.staleTime ?? StaleTime.MINUTE,
    },
  );

  return {
    gameState: data
      ? { ...data, episodeId: data.episodeId === null ? '' : data.episodeId }
      : undefined,
    status,
    isLoading,
    error,
  };
};
