import { Box, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';
import { DefaultTheme } from 'styled-components';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics, useIntl } from 'hooks';

import VtmGoButtonBig from 'components/@common/VtmGoButtonBig';
import WeightTypography from 'components/@common/WeightTypography';

import { Wrap } from './VtmGoBanner.style';

const VtmGoBanner: React.VFC = () => {
  const intl = useIntl();

  const isSm = useMediaQuery<DefaultTheme>(
    (theme) => theme.breakpoints.up('sm'),
    { noSsr: true },
  );

  const { trackEvent } = useAnalytics();

  return (
    <Wrap
      onClick={() => {
        trackEvent({
          eventName: EventName.ClickVtmGoBanner,
          data: { screen_name: PageView.Home },
        });

        window.open(
          intl.formatMessage({ id: 'home.vtm_go_banner.button.link' }),
          '_blank',
        );
      }}
    >
      <Box mb={7} maxWidth={{ xs: '270px', sm: '440px' }} textAlign="center">
        <WeightTypography variant={isSm ? 'h2' : 'body2'}>
          <FormattedMessage id="home.vtm_go_banner.title" />
        </WeightTypography>
      </Box>
      <VtmGoButtonBig>
        <FormattedMessage id="home.vtm_go_banner.button.text" />
      </VtmGoButtonBig>
    </Wrap>
  );
};

export default VtmGoBanner;
