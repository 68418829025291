enum Keys {
  GameState = 'game-state',
  Guesses = 'guesses',
  Lock = 'lock',
  Score = 'score',
  Episode = 'episode',
  Leaderboard = 'leaderboard',
}

export const QueryKeys = {
  gameState: {
    default: () => [Keys.GameState],
  },
  episode: {
    all: () => [Keys.Episode],
  },
  guesses: {
    byEpisode: (episode: string) => [Keys.Guesses, episode],
  },
  locks: {
    byEpisode: (episode: string) => [Keys.Lock, episode],
  },
  scoreBreakdown: {
    all: () => [Keys.Score],
    byUser: (userId: string) => [Keys.Score, userId],
  },
  leaderboard: {
    default: () => [Keys.Leaderboard],
    all: (episode: boolean) => [Keys.Leaderboard, episode ? 'episode' : 'all'],
    byUser: (userId: string, episode: boolean) => [
      Keys.Leaderboard,
      userId,
      episode ? 'episode' : 'all',
    ],
  },
};
