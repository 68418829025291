import { Box, Container } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useIntl } from 'hooks';

import VtmGoButton from 'components/@common/VtmGoButton';

import Broadcast from '../Broadcast';
import {
  Gradients,
  HeaderDescription,
  HeaderTitle,
  HeaderWrap,
} from './Header.style';

interface Props {
  liveDate?: Date;
  isLive?: boolean;
  loading?: boolean;
}

const Header: React.VFC<Props> = ({ isLive, liveDate, loading = false }) => {
  const intl = useIntl();
  return (
    <HeaderWrap
      component="header"
      pt={{ xs: 38, sm: 72 }}
      pb={{ xs: 0, sm: 7 }}
    >
      <Gradients />
      <Container maxWidth="lg" disableGutters>
        <Box px={5} position="relative">
          <HeaderTitle variant="h2" color="inherit">
            <b>
              <FormattedMessage id="home.header.title" />
            </b>
          </HeaderTitle>
          <Box mt={5} mb={7}>
            <HeaderDescription variant="body2" color="inherit">
              <FormattedMessage id="home.header.description" />
            </HeaderDescription>
          </Box>
          {!loading && !liveDate && (
            <Box maxWidth={150} pb={5}>
              <VtmGoButton
                href={intl.formatMessage({
                  id: 'home.vtm_go_button.not_live.link',
                })}
              >
                <FormattedMessage id="home.vtm_go_button.not_live.text" />
              </VtmGoButton>
            </Box>
          )}
        </Box>
        <Box px={{ xs: 0, sm: 5 }} position="relative">
          {(loading || liveDate) && (
            <Broadcast liveDate={liveDate} isLive={isLive} />
          )}
        </Box>
      </Container>
    </HeaderWrap>
  );
};

export default Header;
