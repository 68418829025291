import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { Black } from '../Black';

const LiveBadge: React.FC = () => (
  <Box
    py={0.75}
    px={1.5}
    bgcolor="global01.500"
    color="neutral01.500"
    display="inline-flex"
    alignItems="center"
    borderRadius="42px"
  >
    <Box
      bgcolor="neutral01.500"
      borderRadius="50%"
      boxShadow={'0px 0px 4.13187px 2.06593px #FFE7FB'}
      height="6px"
      width="6px"
      display="inline-block"
      mr={1}
    />
    <Typography variant="h6" display="inline" color="inherit" component="p">
      <Black>
        <FormattedMessage id="broadcast.live.label" />
      </Black>
    </Typography>
  </Box>
);

export default LiveBadge;
