import { Box, CircularProgress, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useWakeLock } from 'react-screen-wake-lock';

import { inIframe } from 'utils/window.utils';

import { EventName, PageView } from 'constants/analytics';
import { useAnalytics } from 'hooks';
import { useGameState, useGuesses, useLocks } from 'queries';
import { historyService } from 'services';
import { RoundState } from 'types/game.types';

import { Routes } from 'pages/routes.constants';

import PageContainer from 'components/@common/PageContainer';
import { IcoClose } from 'components/@icons';
import PreEpisodeState from 'components/@states/PreEpisodeState';
import PreRoundState from 'components/@states/PreRoundState';
import RevealState from 'components/@states/RevealState';
import VoteState from 'components/@states/VoteState';

const Game: React.VFC = () => {
  useWakeLock();
  const { trackPageView, trackEvent } = useAnalytics();
  const { gameState, isLoading } = useGameState();

  const { isLoading: isLoadingGuesses } = useGuesses(gameState?.episodeId);
  const { isLoading: isLoadingLocks } = useLocks(gameState?.episodeId);

  useEffect(() => {
    if (gameState) {
      let pageToTrack: string;
      switch (gameState.roundState) {
        case RoundState.PreEpisode:
          pageToTrack = PageView.RoundInfo;
          break;
        case RoundState.PreRound:
          pageToTrack = PageView.RoundWaiting;
          break;
        case RoundState.VotesOpen:
          pageToTrack = PageView.RoundVoting;
          break;
        case RoundState.VotesClosed:
          pageToTrack = PageView.RoundLocked;
          break;
        case RoundState.DropOut:
          pageToTrack = PageView.RoundPreReveal;
          break;
        case RoundState.Reveal:
          pageToTrack = PageView.RoundReveal;
          break;
      }

      trackPageView({
        path: pageToTrack.replace('{x}', `${gameState.roundNumber}`),
      });

      if (gameState.roundState === RoundState.Reveal) {
        trackEvent({
          eventName: EventName.CompleteRound,
          data: {
            screen_name: PageView.RoundVoting.replace(
              '{x}',
              `${gameState.roundNumber}`,
            ),
            round_number: gameState.roundNumber,
          },
        });
      }
    }
  }, [gameState, trackEvent, trackPageView]);

  if (isLoadingGuesses || isLoadingLocks || isLoading || !gameState) {
    return (
      <PageContainer>
        <Box height={400} display="flex" alignItems="center">
          <CircularProgress />
        </Box>
      </PageContainer>
    );
  }

  const roundState = gameState.roundState;

  const handleBack = () => {
    historyService.replace(Routes.Root);
  };

  return (
    <Box position="relative">
      <PageContainer style={{ position: 'relative' }}>
        <Box
          position="absolute"
          right={0}
          top={inIframe() ? 72 : 8}
          zIndex={100}
        >
          <IconButton onClick={handleBack}>
            <IcoClose />
          </IconButton>
        </Box>
      </PageContainer>
      {roundState === RoundState.PreEpisode && <PreEpisodeState />}
      {roundState === RoundState.PreRound && (
        <PreRoundState round={gameState.roundNumber} />
      )}
      {(roundState === RoundState.VotesOpen ||
        roundState === RoundState.VotesClosed) && (
        <VoteState gameState={gameState} />
      )}
      {(roundState === RoundState.DropOut ||
        roundState === RoundState.Reveal) && (
        <RevealState gameState={gameState} />
      )}
    </Box>
  );
};

export default Game;
