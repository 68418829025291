import { Box, Button, Container, Link, Typography } from '@material-ui/core';
import qs from 'query-string';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { historyService } from 'services';

import { Routes } from 'pages/routes.constants';

import { Black } from 'components/@common/Black';

const LoginCallback: React.VFC = () => {
  const { error } = qs.parse(window.location.search);

  const errorType = typeof error === 'string' ? error : undefined;

  let errorMessageId = 'login.error.general.description';
  if (errorType === 'missing_first_name') {
    errorMessageId = 'login.error.missing_firstname.description';
  }

  return (
    <Box
      bgcolor="neutral06.500"
      pt={20}
      pb={8}
      textAlign="center"
      overflow="hidden"
    >
      <Container maxWidth="md">
        <Box mx={4} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h2">
            <Black>
              <FormattedMessage id="login.error.title" />
            </Black>
          </Typography>
          <Box mt={2} mb={5} maxWidth={350} margin="auto">
            <Typography variant="body1">
              <FormattedMessage id={errorMessageId} />
            </Typography>
          </Box>
          {errorType === 'missing_first_name' ? (
            <Link href="https://myaccount.vtm.be/#/profile" target="_blank">
              <FormattedMessage id="login.error.missing_firstname.change.button" />
            </Link>
          ) : (
            <Typography variant="h5">
              <b>Error:</b> {errorType}
            </Typography>
          )}
          <Box mt={4}>
            <Button onClick={() => historyService.replace(Routes.Root)}>
              <FormattedMessage id="login.error.home.button" />
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginCallback;
