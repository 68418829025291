import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useEpisodes } from 'queries';
import { Breakdown, ScoreBreakdown } from 'types/score.types';

import { Black } from 'components/@common/Black';
import GradientTypography from 'components/@common/GradientTypography';

interface ItemProps {
  breakdown: Breakdown;
  titlePrefix?: string;
  userName?: string;
}

const BreakdownItem: React.FC<ItemProps> = ({ breakdown }) => {
  const { episodes } = useEpisodes();
  const { performers, episodeId } = breakdown;
  const currentEpisode = episodes?.find((e) => e.id === episodeId);

  if (performers.length === 0) {
    return null;
  }

  return (
    <>
      <Box mb={6}>
        <Typography variant="h4" color="primary">
          <Black uppercase>
            <FormattedMessage id="home.episodes.episode.label" />
            &nbsp;
            {currentEpisode?.guestArtist}
          </Black>
        </Typography>
      </Box>
      <Box>
        {performers.map((p) => (
          <Box key={p.performerId} mb={8}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">
                <Black uppercase>
                  <FormattedMessage
                    id={
                      p.isReal
                        ? 'score_breakdown.was_real.label'
                        : 'score_breakdown.was_fake.label'
                    }
                    values={{ name: p.performerName }}
                  />
                </Black>
              </Typography>

              <GradientTypography variant="h2" color="gradient07" glow>
                + {p.lockedPoints}
              </GradientTypography>
            </Box>
            <Box color="neutral05.500" clone>
              <Typography variant="h6">
                <FormattedMessage
                  id={
                    p.lockedPoints > 0
                      ? 'score_breakdown.correct.label'
                      : 'score_breakdown.wrong.label'
                  }
                />
              </Typography>
            </Box>
            {p.streak > 1 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box color="neutral05.500" clone>
                  <Typography variant="h6">
                    <FormattedMessage
                      id="score_breakdown.correct.streak.label"
                      values={{ streak: p.streak }}
                    />
                  </Typography>
                </Box>
                <GradientTypography variant="h2" color="gradient07" glow>
                  + {p.streakPoints}
                </GradientTypography>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

interface Props {
  breakdown: ScoreBreakdown;
}

const ScoreBreakdownComp: React.FC<Props> = ({ breakdown }) => {
  return (
    <Box textAlign="left" borderRadius="10px">
      {breakdown.breakdown.map((b) => (
        <BreakdownItem key={b.episodeId} breakdown={b} />
      ))}
    </Box>
  );
};

export default ScoreBreakdownComp;
