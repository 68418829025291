import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { Lock } from 'types/guess.types';

const getLocks = async () => {
  const { data: response } = await apiService.getLock();
  return response;
};

export const useLocks = (
  episodeId = '',
  config: UseCommonQueryOptions<Lock[]> = { enabled: true },
) => {
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.locks.byEpisode(episodeId),
    () => getLocks(),
    {
      ...config,
      enabled: episodeId.length > 0 && config.enabled,
      staleTime: config?.staleTime ?? StaleTime.FIVE_MIN,
    },
  );

  const roundLock = useCallback(
    (roundNumber: number) => {
      return data?.find((l) => l.roundNumber === roundNumber);
    },
    [data],
  );

  return {
    roundLock,
    locks: data,
    status,
    isLoading,
    error,
  };
};
