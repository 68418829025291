import { Box, CircularProgress, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { nl } from 'date-fns/esm/locale';
import React from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useIntl } from 'hooks';

import LiveBadge from 'components/@common/LiveBadge';
import VtmGoButton from 'components/@common/VtmGoButton';
import WeightTypography from 'components/@common/WeightTypography';

import { BroadcastWrap } from './Broadcast.style';

interface Props {
  liveDate?: Date;
  isLive?: boolean;
}

const Broadcast: React.VFC<Props> = ({ liveDate, isLive }) => {
  const intl = useIntl();
  const vtmGoUrl = intl.formatMessage({
    id: isLive
      ? 'home.vtm_go_button.live.link'
      : 'home.vtm_go_button.not_live.link',
  });
  return (
    <BroadcastWrap>
      {liveDate ? (
        <>
          {isLive ? (
            <Box display="flex" flexDirection="column" mr={10}>
              <Box mb={3}>
                <LiveBadge />
              </Box>
              <Typography>
                <b>
                  <FormattedMessage id="broadcast.is_live.text" />
                </b>
              </Typography>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" mr={{ xs: 0, sm: 10 }}>
              <Box color="global01.500" clone>
                <WeightTypography variant="body2">
                  <FormattedMessage id="broadcast.next_episode.text" />
                </WeightTypography>
              </Box>
              <Box display="flex" flexWrap="wrap">
                <Box mr={1}>
                  <Typography variant="h3">
                    <b>{format(liveDate, 'eeee', { locale: nl })}</b>
                  </Typography>
                </Box>
                <Typography variant="h3">
                  {format(liveDate, 'HH.mm', { locale: nl })}
                </Typography>
              </Box>
            </Box>
          )}

          {!vtmGoUrl.includes('home.vtm_go_button') && (
            <Box flexShrink={0} clone>
              <VtmGoButton href={vtmGoUrl}>
                <FormattedMessage
                  id={
                    isLive
                      ? 'home.vtm_go_button.live.text'
                      : 'home.vtm_go_button.not_live.text'
                  }
                />
              </VtmGoButton>
            </Box>
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </BroadcastWrap>
  );
};

export default Broadcast;
