import { Box, ListItem, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedNumber } from 'react-intl';

import { Black } from 'components/@common/Black';
import IcoCrown from 'components/@icons/IcoCrown';

interface Props {
  position?: number;
  name: string;
  score?: number;
  isCurrentUser?: boolean;

  variant?: 'light' | 'dark';
}

const LeaderboardListItem: React.VFC<Props> = ({
  position,
  name,
  score,
  isCurrentUser = false,
  variant = 'dark',
}) => {
  const title = !!position ? (
    <>
      <FormattedNumber value={position} />. {name}
    </>
  ) : (
    name
  );
  return (
    <>
      <ListItem
        disableGutters
        style={{
          height: 51,
          boxShadow: isCurrentUser ? '0px 0px 12.3px #FF14D9' : undefined,
          background: isCurrentUser
            ? 'linear-gradient(268.93deg, #FFC5F6 2.81%, #FF64E6 33.45%, #FF14D9 88.09%), #FD14D8'
            : undefined,
          backgroundColor:
            variant === 'light' ? 'rgba(51, 99, 253, 0.2)' : 'transparent',
        }}
      >
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          mx={5}
        >
          <Typography variant="h5" color="textPrimary">
            <Black>{title}</Black>
          </Typography>
          <Box display="flex" alignItems="center">
            {score !== undefined && (
              <Box
                display="flex"
                color={isCurrentUser ? 'neutral02.600' : 'neutral01.500'}
                pt="2px"
                clone
              >
                <Typography variant="h5">
                  <FormattedNumber value={score} />
                </Typography>
              </Box>
            )}
            {position !== undefined && position <= 3 && (
              <Box
                color={position === 1 ? 'neutral01.500' : 'neutral05.500'}
                ml={2}
                clone
              >
                <IcoCrown
                  fontSize={22}
                  style={{
                    filter:
                      position === 1
                        ? 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.6)'
                        : undefined,
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </ListItem>
    </>
  );
};

export default LeaderboardListItem;
