import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import GradientTypography from 'components/@common/GradientTypography';

export const GradientTypographyStyled = styled(GradientTypography)`
  text-shadow: 0px 0px 11.8321px rgba(255, 255, 255, 0.6);
  line-height: 125%;
`;

export const BlueText = styled(Typography)`
  color: #819fff;
  text-shadow: s0px 0px 14.1985px rgba(255, 255, 255, 0.45);
`;
