import API, { graphqlOperation } from '@aws-amplify/api';
import * as Sentry from '@sentry/browser';
import _get from 'lodash/get';
import Observable, { ZenObservable } from 'zen-observable-ts';

import { QueryKeys } from 'queries';
import { queryClient } from 'services/react-query';

import awsconfig from './aws-config';
import { subscribeGameData } from './subscriptions';

enum EventType {
  Score = 'SCORE',
}

type BroadCast = {
  eventType: EventType;
  payload: string;
};
class AwsService {
  private subscription: ZenObservable.Subscription | undefined;

  constructor() {
    if (awsconfig.API.aws_appsync_apiKey.length) {
      API.configure(awsconfig.API);
      this.subscribe();

      window.addEventListener('online', () => this.handleOnline);
    }
  }

  private handleOnline() {
    if (!this.subscription) {
      this.subscribe();
    }
  }

  public subscribe() {
    if (this.subscription) {
      return;
    }

    const sub = API.graphql(graphqlOperation(subscribeGameData));

    if (sub instanceof Observable) {
      this.subscription = sub.subscribe({
        error: (error) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              name: 'aws-appsync',
              errorMessage: _get(error, 'error.errors[0].message'),
              errorJson: JSON.stringify(error),
            });
            Sentry.captureException(error);
          });

          // Try to reconnect on connection closed
          const status = error?.provider?.socketStatus;
          if (status <= 1) {
            this.unsubscribe();
            setTimeout(() => {
              this.subscribe();
            }, 500);
          }
        },
        next: (payload: {
          value: { data: { onBroadcastEvent: BroadCast } };
        }) => {
          try {
            const item = payload.value.data.onBroadcastEvent;

            if (item.eventType === EventType.Score) {
              // Refetch the user score
              queryClient.invalidateQueries(QueryKeys.scoreBreakdown.all());
              queryClient.invalidateQueries(QueryKeys.leaderboard.default());
            }
          } catch (error) {
            Sentry.withScope((scope) => {
              scope.setExtras({ name: 'aws-appsync-catch' });
              Sentry.captureException(error);
            });
          }
        },
      });
    }
  }

  public unsubscribe() {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }
}

export default AwsService;
