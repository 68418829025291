import { useCallback, useEffect, useRef, useState } from 'react';

export const useSprite = (
  name: string,
  start: number,
  end: number,
  stopFrame?: number,
  onStop?: () => void,
  shouldAnimate = true,
  fps = 25,
) => {
  const prevTime = useRef(0);
  const [currentFrame, setCurrentFrame] = useState(start);
  const interval = 1000 / fps;

  const animate = useCallback(
    (nextFrame: number, time: number): number => {
      if (!prevTime.current) {
        prevTime.current = time;
      }

      if (shouldAnimate) {
        const delta = time - prevTime.current;
        if (delta < interval) {
          return requestAnimationFrame((time) => animate(nextFrame, time));
        }

        prevTime.current = time - (delta % interval);
        setCurrentFrame(nextFrame);
      } else {
        prevTime.current = 0;
      }
      return 0;
    },
    [interval, shouldAnimate],
  );

  useEffect(() => {
    if (shouldAnimate) {
      const nextFrame = currentFrame + 1 >= end ? start : currentFrame + 1;

      if (!shouldAnimate) {
        return;
      }
      if (stopFrame && nextFrame === stopFrame - 1) {
        onStop?.();
        return;
      }

      let id = requestAnimationFrame((time) => {
        id = animate(nextFrame, time);
      });
      return () => {
        cancelAnimationFrame(id);
      };
    }
  }, [animate, currentFrame, end, shouldAnimate, start, stopFrame, onStop]);

  const paddedFrame = `${currentFrame}`.padStart(2, '0');
  return `${name}${paddedFrame} sprite`;
};
