import { Link } from '@material-ui/core';
import styled from 'styled-components';

export const LinkStyled = styled(Link).attrs({ underline: 'none' })`
  position: relative;
  background-color: ${({ theme }) => theme.palette.global01['300']};
  border-radius: 60px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.neutral01[500]};
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.palette.neutral01[500]};
  }
`;
