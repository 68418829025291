import { Guess, Lock, PostGuessBody } from 'types/guess.types';

import RestApiService from './';

export default {
  getGuesses(this: RestApiService, episodeId: string) {
    return this.api.get<Guess[]>(`/episodes/${episodeId}/guess`, {
      authEnabled: true,
    });
  },
  postGuess(this: RestApiService, userId: string, data: PostGuessBody) {
    return this.api.post<Guess>(`/users/${userId}/guess`, data, {
      authEnabled: true,
    });
  },
  postLock(this: RestApiService) {
    return this.api.post<Lock>('/lock', undefined, {
      authEnabled: true,
    });
  },
  getLock(this: RestApiService) {
    return this.api.get<Lock[]>('/lock', {
      authEnabled: true,
    });
  },
};
