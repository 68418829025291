import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from 'hooks';
import { QueryKeys } from 'queries';
import { StaleTime } from 'queries/constants';
import { UseCommonQueryOptions } from 'queries/types';
import { apiService } from 'services';
import { queryClient } from 'services/react-query';
import { ScoreBreakdown } from 'types/score.types';

const getScoreBreakdown = async (userId: string) => {
  const { data: response } = await apiService.getScoreBreakdown(userId);
  return response;
};

export const prefetchScoreBreakdown = async (memberId: string) => {
  try {
    await queryClient.prefetchQuery(
      QueryKeys.scoreBreakdown.byUser(memberId),
      () => getScoreBreakdown(memberId),
      { staleTime: StaleTime.MINUTE },
    );
  } catch {}
};

export const useScoreBreakdown = (
  config: UseCommonQueryOptions<ScoreBreakdown> = { enabled: true },
) => {
  const { userId } = useAuth();
  const { data, status, isLoading, error } = useQuery(
    QueryKeys.scoreBreakdown.byUser(userId ?? ''),
    // We can disable it because the query isn't enabled without a valid userId
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => getScoreBreakdown(userId!),
    {
      ...config,
      enabled: !!userId && config.enabled,
      cacheTime: config?.cacheTime ?? StaleTime.FOREVER,
      staleTime: config?.staleTime ?? StaleTime.MINUTE,
    },
  );

  const scoreForPerformer = useCallback(
    (episodeId: string, performerId: string) => {
      const score = data?.breakdown
        .find((b) => b.episodeId === episodeId)
        ?.performers.find((p) => p.performerId === performerId);
      return score;
    },
    [data?.breakdown],
  );

  return {
    scoreForPerformer,
    scoreBreakdown: data,
    status,
    isLoading,
    error,
  };
};
