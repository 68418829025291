import { Box, Container, ContainerProps } from '@material-ui/core';
import { SpacingProps } from '@material-ui/system';
import React from 'react';

interface Props extends SpacingProps {
  bgcolor?: string;
}

const PageContainer: React.FC<Props & ContainerProps> = ({
  children,
  bgcolor,
  css,
  maxWidth = 'md',
  ...rest
}) => (
  <Box bgcolor={bgcolor} width="100%" position="relative">
    <Container css={css} maxWidth={maxWidth} disableGutters>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        {...rest}
      >
        {children}
      </Box>
    </Container>
  </Box>
);

export default PageContainer;
