import styled from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  background: ${({ theme }) => theme.palette.gradient05.main};
  border: 1px solid ${({ theme }) => theme.palette.global02[500]};
  box-shadow: 0px 0px 12.3px rgba(116, 202, 255, 0.9);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(4)}px;
  width: 440px;
  min-height: 132px;
  max-width: 100%;
`;
