import { Box } from '@material-ui/core';
import React from 'react';

const PageMargin: React.FC = ({ children }) => (
  <Box
    width="100%"
    display="flex"
    flexDirection="column"
    alignItems="center"
    textAlign="center"
    justifyContent="center"
    px={{ xs: 4, sm: 0 }}
  >
    {children}
  </Box>
);

export default PageMargin;
