import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { useEpisodes } from 'queries';

import { Black } from 'components/@common/Black';
import GradientTypography from 'components/@common/GradientTypography';
import NavigationButton from 'components/@common/NavigationButton';
import PageMargin from 'components/@common/PageMargin';
import VideoGallery from 'components/@common/VideoGallery';
import { IcoArrowLeft, IcoArrowRight } from 'components/@icons';

const Episodes: React.VFC = () => {
  const { episodes } = useEpisodes();

  const [activeEpisodeIdx, setActiveEpisodeIdx] = useState(0);

  const finalEpisodes = episodes?.filter((e) => e.linkedPlaylist.length > 0);
  if (!finalEpisodes || finalEpisodes.length === 0) {
    return null;
  }

  const activeEpisode = finalEpisodes[activeEpisodeIdx];

  return (
    <>
      <PageMargin>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          maxWidth={400}
          justifyContent="space-between"
          mb={5}
        >
          <NavigationButton
            disabled={activeEpisodeIdx <= 0}
            onClick={() => setActiveEpisodeIdx((idx) => idx - 1)}
          >
            <IcoArrowLeft />
          </NavigationButton>
          <Box>
            <GradientTypography color="gradient01" variant="h3">
              {activeEpisode.guestArtist.toUpperCase()}
            </GradientTypography>
            <Typography variant="h5">
              <Black>
                <FormattedMessage id="home.episodes.episode.label" />
                &nbsp;
                {activeEpisode.episodeNumber}
              </Black>
            </Typography>
          </Box>
          <NavigationButton
            disabled={activeEpisodeIdx >= finalEpisodes.length - 1}
            onClick={() => setActiveEpisodeIdx((idx) => idx + 1)}
          >
            <IcoArrowRight />
          </NavigationButton>
        </Box>
      </PageMargin>
      {finalEpisodes.map((episode) => (
        <Box
          key={episode.id}
          display={episode.id === activeEpisode.id ? 'block' : 'none'}
        >
          <VideoGallery id={episode.linkedPlaylist} />
        </Box>
      ))}
    </>
  );
};

export default Episodes;
