import React from 'react';
import styled from 'styled-components';

import imgBackgroundDesktop from 'assets/png/bg-lights-desktop.png';
import imgBackgroundMobile from 'assets/png/bg-lights-mobile.png';

const Background = styled.div`
  width: 100%;
  height: 250px;

  background-image: url(${imgBackgroundMobile});
  background-position: center bottom;
  background-size: cover;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 320px;
    background-image: url(${imgBackgroundDesktop});
  }
`;

const LightsBackground: React.VFC = () => {
  return <Background />;
};

export default LightsBackground;
