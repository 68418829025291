import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography).attrs({ variant: 'h6' })`
  text-transform: uppercase;
  font-weight: 900;
`;

export const Label = styled(Typography).attrs({ variant: 'h5' })`
  color: ${({ theme }) => theme.palette.neutral05[500]};
  text-shadow: 0px 0px 12px rgba(255, 255, 255, 0.45);
`;
