import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import _isEqual from 'lodash/isEqual';
import { useCallback, useState } from 'react';

import { EventName, PageView } from 'constants/analytics';
import { useAuth } from 'hooks/useAuth';

interface Params {
  eventName: EventName;
  data?: {};
  once?: boolean;
}

interface PageViewParams {
  path: PageView | string;
  data?: {};
  once?: boolean;
}

export const useAnalytics = () => {
  const sendDataToGTM = useGTMDispatch();
  const { isLoggedIn } = useAuth();
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const [prevPageData, setPrevPageData] = useState<object | undefined>();
  const [prevEventData, setPrevEventData] = useState<object | undefined>();

  const trackPageView = useCallback(
    ({ path, data = {}, once = true }: PageViewParams) => {
      const trackData = {
        event: 'screenview',
        userStatus: isLoggedIn ? 'loggedin' : 'anonymous',
        page: {
          id: '',
          title: 'I can see your voice',
          pubDate: '',
          brand: 'vtm',
          language: 'nl',
          type: 'Interactive',
          subType: 'game',
          path,
        },
        program: 'I can see your voice',
        ...data,
      };

      if (
        (once && !hasBeenCalled) ||
        !once ||
        (prevPageData && !_isEqual(trackData, prevPageData))
      ) {
        setHasBeenCalled(true);
        setPrevPageData(trackData);
        sendDataToGTM({ ...trackData });
      }
    },
    [isLoggedIn, hasBeenCalled, prevPageData, sendDataToGTM],
  );

  const trackEvent = useCallback(
    ({ eventName, data = {}, once = true }: Params) => {
      const trackData = {
        event: eventName,
        eventCategory: 'game',
        eventLabel: 'i-can-see-your-voice',
        ...data,
      };

      if (
        (once && !hasBeenCalled) ||
        !once ||
        (prevEventData && !_isEqual(trackData, prevEventData))
      ) {
        setHasBeenCalled(true);
        setPrevEventData(trackData);
        sendDataToGTM({ ...trackData });
      }
    },
    [hasBeenCalled, prevEventData, sendDataToGTM],
  );

  return {
    trackEvent,
    trackPageView,
  };
};
