import { differenceInSeconds, isPast } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';

import RoundBonus from './RoundBonus';

interface Props {
  startTime: Date;
  endTime: Date;
  lockedTime?: Date;
  roundNumber: number;
  finalScore?: number;
}

const MAX_SCORE = 1000;
const MIN_SCORE = 50;

const RoundBonusContainer: React.VFC<Props> = ({
  roundNumber,
  lockedTime,
  startTime,
  endTime,
  finalScore,
}) => {
  const maxTime = differenceInSeconds(endTime, startTime);
  const [score, setScore] = useState(MAX_SCORE);

  const getCurrentScore = useCallback(() => {
    return differenceInSeconds(endTime, new Date());
  }, [endTime]);

  useEffect(() => {
    setScore(
      Math.max(
        Math.round((getCurrentScore() / maxTime) * MAX_SCORE),
        MIN_SCORE,
      ),
    );

    const interval = setInterval(() => {
      if ((!!!lockedTime || !isPast(lockedTime)) && !finalScore) {
        setScore(
          Math.max(
            Math.round((getCurrentScore() / maxTime) * MAX_SCORE),
            MIN_SCORE,
          ),
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime, getCurrentScore, lockedTime, maxTime, finalScore]);

  return (
    <RoundBonus
      maxScore={MAX_SCORE}
      minScore={MIN_SCORE}
      locked={!!lockedTime}
      roundNumber={roundNumber}
      score={finalScore ?? score}
    />
  );
};

export default RoundBonusContainer;
