import { Box, CircularProgress } from '@material-ui/core';
import { FC } from 'react';

const LoadingState: FC = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexGrow="1"
    height="100%"
  >
    <CircularProgress />
  </Box>
);

export default LoadingState;
