import 'swiper/swiper.min.css';

import { Box } from '@material-ui/core';
import React from 'react';

import type { Performer as PerformerType } from 'types/game.types';

import {
  PerformerImage,
  PerformerName,
  PerformerPlatform,
  PlatformOffset,
} from './Performer.style';

interface Props {
  performer: PerformerType;
  isReal?: boolean;
  className?: string;
}

const Performer: React.VFC<Props> = ({ performer, isReal, className }) => {
  return (
    <Box className={className}>
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <PerformerImage alt={performer.name} src={performer.imageUrl} />
        <PerformerPlatform $isReal={isReal} />
        <Box width="100%" display="flex" justifyContent="center" height={30}>
          <PerformerName variant="h3" color="gradient02">
            {performer.name}
          </PerformerName>
        </Box>
      </Box>
      <PlatformOffset />
    </Box>
  );
};

export default Performer;
