import { isProd } from 'utils/env.utils';

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  API_BASE: string;
  SERVER_URL: string;
  CLIENT_URL: string;
  IFRAME_CLIENT_URL: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
    tracesSampleRate: number;
    sampleRate: number;
  };
  AWS: {
    APPSYNC_ID: string;
    APPSYNC_API_KEY: string;
  };
  VTM_CLIENT_ID: string;
  PHRASE_DEV: boolean;
  ANALYTICS: {
    GTM_ID: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  CLIENT_URL: process.env.REACT_APP_CLIENT_URL || '',
  IFRAME_CLIENT_URL: process.env.REACT_APP_IFRAME_CLIENT_URL || '',
  SERVER_URL: process.env.REACT_APP_SERVER_URL || '',

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',

  // error tracking
  SENTRY: {
    dsn: 'https://c007355eea4e46ac828f68d1c986c2c8@o190235.ingest.sentry.io/6221035',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
    tracesSampleRate: isProd() ? 0.2 : 1.0,
    sampleRate: isProd() ? 0.25 : 1.0,
  },

  VTM_CLIENT_ID: 'vtm-icsyv-web',

  // analytics
  ANALYTICS: {
    GTM_ID: process.env.REACT_APP_TAG_MANAGER_ID ?? '',
  },

  // aws
  AWS: {
    APPSYNC_ID: process.env.REACT_APP_AWS_APPSYNC_ID || '',
    APPSYNC_API_KEY: process.env.REACT_APP_AWS_APPSYNC_API_KEY || '',
  },

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'en-GB',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',
  PHRASE_DEV: process.env.REACT_APP_PHRASE_DEV === 'true' ?? false,
};

export default config;
