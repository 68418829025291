import { Box, CircularProgress, Link, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl-phraseapp';

import { findLastEpisode } from 'utils/episode.utils';
import { roundImage } from 'utils/game.utils';
import { inIframe } from 'utils/window.utils';

import { usePrevious } from 'hooks';
import { useEpisodes } from 'queries';

import GradientTypography from 'components/@common/GradientTypography';
import LightsBackground from 'components/@common/LightsBackground';
import PageContainer from 'components/@common/PageContainer';
import PageMargin from 'components/@common/PageMargin';
import CtaSection from 'components/@home/CtaSection';

import { EyeImg } from './PreEpisodeState.style';

const PreEpisodeState: React.VFC = () => {
  const { episodes, isLoading, refetch } = useEpisodes();
  const currentEpisode = findLastEpisode(episodes ?? []);
  const prevEpisode = usePrevious(currentEpisode);

  useEffect(() => {
    if (!currentEpisode && prevEpisode) {
      refetch();
    }
  }, [currentEpisode, prevEpisode, refetch]);

  return (
    <Box position="relative">
      <Box position="absolute" top={0} left={0} right={0}>
        <LightsBackground />
      </Box>
      <PageContainer pt={inIframe() ? 22 : 10} pb={10}>
        <PageMargin>
          {currentEpisode?.isLive ? (
            <Box mb={10}>
              <Box mb={6}>
                <CircularProgress />
              </Box>
              <Typography variant="body1" color="primary">
                <b>
                  <FormattedMessage id="game.pre_episode.waiting.label" />
                </b>
              </Typography>
            </Box>
          ) : (
            <Box pb={10}>
              <CtaSection
                hideButton
                isLive={currentEpisode?.isLive}
                liveDate={
                  currentEpisode
                    ? new Date(currentEpisode.startDate)
                    : undefined
                }
                loading={isLoading}
              />
            </Box>
          )}
          {/* @ts-ignore */}
          <GradientTypography component="h1" color="gradient02" glow>
            <FormattedMessage id="game.pre_episode.title" />
          </GradientTypography>
          <GradientTypography color="gradient01" variant="h2">
            <FormattedMessage id="game.pre_episode.sub_title" />
          </GradientTypography>
          {/* Round 1 */}
          <Box maxWidth={305} mb={11} mt={10}>
            <EyeImg src={roundImage(1)} alt="Ronde 1" />
            <Typography variant="body1">
              <FormattedMessage id="game.pre_episode.round_1.text" />
            </Typography>
          </Box>

          {/* Round 2 */}
          <Box maxWidth={305} mb={11}>
            <EyeImg src={roundImage(2)} alt="Ronde 2" />
            <Typography variant="body1">
              <FormattedMessage id="game.pre_episode.round_2.text" />
            </Typography>
          </Box>

          {/* Round 3 */}
          <Box maxWidth={305} mb={11}>
            <EyeImg src={roundImage(4)} alt="Ronde 3" />
            <Typography variant="body1">
              <FormattedMessage id="game.pre_episode.round_3.text" />
            </Typography>
          </Box>

          {/* Round 4 */}
          <Box maxWidth={305} mb={11}>
            <EyeImg src={roundImage(5)} alt="Ronde 4" />
            <Typography variant="body1">
              <FormattedMessage id="game.pre_episode.round_4.text" />
            </Typography>
          </Box>

          {/* Round 5 */}
          <Box maxWidth={305}>
            <EyeImg src={roundImage(6)} alt="Ronde 5" />
            <Typography variant="body1">
              <FormattedMessage id="game.pre_episode.round_5.text" />
            </Typography>
          </Box>

          <Box mt={13} mb={4}>
            <Link
              href="https://vtm.be/i-can-see-your-voice/wedstrijdreglement-i-can-see-your-voice"
              target="_blank"
            >
              <FormattedMessage id="game.pre_episode.check_rules.text" />
            </Link>
          </Box>
        </PageMargin>
      </PageContainer>
    </Box>
  );
};

export default PreEpisodeState;
