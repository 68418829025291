import React from 'react';
import styled from 'styled-components';

// TODO: this is temp styling
const Wrap = styled.div`
  width: 100%;

  .mychannels-inline-playlist {
    border: none !important;
    border-radius: 0 !important;
    background: transparent !important;
    color: white !important;
    max-width: 100% !important;
  }

  .mychannels-inline-playlist__divider {
    border-bottom: none !important;
  }

  .mychannels-inline-playlist__title {
    font-size: 16px !important;
    font-family: 'Futura' !important;
  }

  .mychannels-inline-playlist__description {
    font-size: 12px !important;
    font-family: 'Futura' !important;
  }

  .mychannels-inline-playlist__item-title {
    font-size: 12px !important;
    height: calc(12px * 3) !important;
    font-weight: bold !important;
    font-family: 'Futura' !important;
  }

  .mychannels-inline-playlist__item-time {
    font-size: 12px !important;
    font-weight: bold !important;
    color: #fa00ff !important;
  }

  .pui__startplay {
    background-color: #2d03b5 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }

  .mychannels-inline-playlist__play {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }

  .mychannels-inline-playlist__play svg {
    fill: #2d03b5 !important;
  }

  .mychannels-inline-playlist__list-control {
    background: #2d03b5 !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }

  .mychannels-inline-playlist__label {
    background: #2d03b5 !important;
  }

  .mychannels-inline-playlist__visual {
    border-radius: ${({ theme }) => theme.shape.radius(2.5)}px;
    overflow: hidden;
  }

  .mychannels-inline-playlist__player {
    border-radius: ${({ theme }) => theme.shape.radius(2.5)}px;
    overflow: hidden;
  }

  .pui__root .pui__seekbar__bars__progress {
    background-image: linear-gradient(to right, #2d03b5, #1a0269) !important;
  }
`;

interface Props {
  id: string;
}

const VideoGallery: React.FC<Props> = ({ id }) => {
  return (
    <Wrap
      id={id}
      className="mychannels"
      data-mychannels-type="video-playlist-inline"
      data-mychannels-id={id}
      data-mychannels-options="UUUUU_default"
    />
  );
};

export default VideoGallery;
