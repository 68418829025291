import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface Props extends Omit<TypographyProps, 'color'> {
  fontWeight?: number | string;
}

export const TypographyStyled = styled(Typography)<{
  $fontWeight: Props['fontWeight'];
}>`
  font-weight: ${({ $fontWeight }) => $fontWeight};
`;

const WeightTypography: React.FC<Props> = ({
  children,
  fontWeight = 600,
  ...rest
}) => (
  <TypographyStyled $fontWeight={fontWeight} {...rest}>
    {children}
  </TypographyStyled>
);

export default WeightTypography;
