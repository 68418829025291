export enum EventName {
  ClickPlay = 'click_speel_mee',
  LoginSuccess = 'login_success',
  ClickVtmGo = 'click_kijk_vtm_go',
  ClickVtmGoBanner = 'click_kijk_vtm_go_banner',
  ClickLeaderboard = 'click_leaderboard',
  ClickTutorial = 'click_tutorial',
  ClickPrizes = 'click_prizes',
  ClickVoteSlecht = 'click_vote_slecht',
  ClickVoteEcht = 'click_vote_echt',
  ClickLock = 'click_lock',
  CompleteRound = 'complete_round',
  ClickArrow = 'click_arrow',
  StreakBonus = 'streak_bonus',
}

export enum PageView {
  Home = 'home',
  RoundInfo = 'round_info',
  RoundWaiting = 'round_{x}_waiting',
  RoundVoting = 'round_{x}_voting',
  RoundLocked = 'round_{x}_locked',
  RoundPreReveal = 'round_{x}_pre_reveal',
  RoundReveal = 'round_{x}_reveal',
  LeaderboardGeneral = 'leaderboard_general',
  LeaderboardEpisode = 'leaderboard_episode',
}

export const DATALAYER_NAME = 'dataLayer';
