import React from 'react';

import type { Performer as PerformerType } from 'types/game.types';
import { Guess } from 'types/guess.types';

import Performer from 'components/@common/Performer';

interface Props {
  performer: PerformerType;
  guess?: Guess;
}

const GameCarouselSlide: React.VFC<Props> = ({ performer, guess }) => {
  return <Performer performer={performer} isReal={guess?.isReal} />;
};

export default GameCarouselSlide;
