import { Theme } from '@material-ui/core';
import { transparentize } from 'polished';

const createOverrides = (theme: Theme): Theme['overrides'] => ({
  MuiCssBaseline: {
    '@global': {
      html: {
        // Set relative html font size based
        fontSize: `${(theme.typography.fontSize / 16) * 100}%`,
      },
      body: {
        backgroundColor: theme.palette.neutral04[500],
      },
    },
  },

  MuiButton: {
    root: {
      background: theme.palette.global02[500],
      boxShadow: `0px 0px 20px rgba(255, 255, 255, 0.45),
        inset 0px 26px 0px rgba(255, 255, 255, 0.2)`,
      borderRadius: '8px',

      marginTop: `${theme.spacing(7)}px`,
      padding: theme.spacing(4, 8),
      fontWeight: 900,
      fontSize: 20,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.global02[500],
        boxShadow: `0px 0px 30px rgba(255, 255, 255, 0.45), inset 0px 26px 0px rgba(255, 255, 255, 0.2)`,

        '@media (hover: none)': {
          background: theme.palette.global02[500],
          boxShadow: `0px 0px 20px rgba(255, 255, 255, 0.45),
        inset 0px 26px 0px rgba(255, 255, 255, 0.2)`,
        },
      },

      '&:disabled': {
        backgroundColor: transparentize(0.8, theme.palette.global02[500]),
        boxShadow: 'none',
        color: transparentize(0.5, theme.palette.neutral01[500]),
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    text: {
      padding: theme.spacing(4, 5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 5),
      },
    },
  },
  MuiLink: {
    root: {
      fontFamily: 'Montserrat',
    },
  },
  MuiTabs: {
    root: {
      background: theme.palette.neutral02[500],
      borderRadius: 48,
      padding: 8,
      width: '100%',
    },
    indicator: {
      display: 'none',
    },
  },
  MuiTab: {
    textColorInherit: {
      opacity: 1,
    },
    root: {
      flex: 1,
      background: 'transparent',
      color: theme.palette.neutral01[500],
      borderRadius: 32,
      opacity: 1,
      fontSize: 14,
      fontWeight: 900,
      '&$selected': {
        background: theme.palette.global02[500],
        boxShadow: `0px 0px 20px rgba(255, 255, 255, 0.45),
        inset 0px 26px 0px rgba(255, 255, 255, 0.2)`,
      },
    },
  },
  MuiSnackbarContent: {
    root: {
      backgroundColor: theme.palette.global01[500],
      borderRadius: 4,
      boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.17)',
    },
  },
  MuiDialog: {
    paper: {
      marginTop: 90,
      backgroundColor: theme.palette.neutral03[500],
      borderRadius: 0,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
    },
  },
  MuiDialogTitle: {
    root: {
      textTransform: 'uppercase',
    },
  },
  MuiDialogContent: {
    dividers: {
      borderTop: '1px solid rgba(255, 255, 255, 0.1)',
      borderBottom: 'none',
    },
  },
});

export { createOverrides };
