import imgEye1 from 'assets/png/img-eye-round-1.png';
import imgEye2 from 'assets/png/img-eye-round-2.png';
import imgEye3 from 'assets/png/img-eye-round-3.png';
import imgEye4 from 'assets/png/img-eye-round-4.png';
import imgEye5 from 'assets/png/img-eye-round-5.png';

export function roundImage(round: number) {
  switch (round) {
    case 1:
      return imgEye1;
    case 2:
    case 3:
      return imgEye2;
    case 4:
      return imgEye3;
    case 5:
      return imgEye4;
    case 6:
      return imgEye5;
  }
}

export function correctRoundNumber(round: number) {
  if (round >= 3) {
    return round - 1;
  }

  return round;
}
