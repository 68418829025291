import { Color, SimplePaletteColorOptions } from '@material-ui/core';

export const global01: Color = {
  50: '',
  100: '',
  200: '',
  300: '#FA00FF',
  400: '',
  500: '#FD14D8',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const global02: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#09A1FF',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral01: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#ffffff',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral02: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#010938',
  600: '#060525',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral03: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '#112A9E',
  500: '#011364',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral04: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#010E80',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const neutral05: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#819FFF',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const succes01: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#1DF152',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const error01: Color = {
  50: '',
  100: '',
  200: '',
  300: '',
  400: '',
  500: '#FF4135',
  600: '',
  700: '',
  800: '',
  900: '',
  A100: '',
  A200: '',
  A400: '',
  A700: '',
};

export const gradient01: SimplePaletteColorOptions = {
  main: 'linear-gradient(180deg, #FF79EA 8.95%, #FF14D9 73.26%)',
};

export const gradient02: SimplePaletteColorOptions = {
  main: 'linear-gradient(178.98deg, #FFFFFF 0.88%, #EEEEEE 66.87%, #D7D7D7 99.11%)',
};

export const gradient03: SimplePaletteColorOptions = {
  main: 'linear-gradient(268.94deg, #00310C 0.91%, #06B331 49.09%, #004A12 99.1%)',
};

export const gradient04: SimplePaletteColorOptions = {
  main: 'linear-gradient(268.94deg, #730700 0.91%, #FF0F00 49.41%, #730700 99.1%)',
};

export const gradient05: SimplePaletteColorOptions = {
  main: 'linear-gradient(268.92deg, #010E80 0.92%, #3363FD 49.49%, #010E80 99.08%)',
};

export const gradient06: SimplePaletteColorOptions = {
  main: 'linear-gradient(268.93deg, rgba(3, 59, 125, 0.4) 2.81%, rgba(51, 99, 253, 0.4) 45%, rgba(3, 59, 125, 0.4) 88.09%)',
};

export const gradient07: SimplePaletteColorOptions = {
  main: 'linear-gradient(268.93deg, #09C4FF 2.81%, #09A1FF 60.1%, #0084D6 88.09%), linear-gradient(0deg, #09A1FF, #09A1FF)',
};
