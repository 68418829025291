import 'swiper/swiper.min.css';

import React, { useRef } from 'react';
import SwiperCore, {
  EffectCoverflow as SwiperEffectCoverflow,
  Navigation as SwiperNavigation,
} from 'swiper';
import { SwiperSlide } from 'swiper/react/swiper-react.js';

import { Performer } from 'types/game.types';
import { Guess } from 'types/guess.types';

import NavigationButton from 'components/@common/NavigationButton';
import { IcoArrowLeft, IcoArrowRight } from 'components/@icons';

import GameCarouselSlide from '../GameCarouselSlide';
import { Navigation, SwiperStyled } from './GameCarousel.style';

interface Props {
  performers: Performer[];
  guesses?: Guess[];
  onSlideChangeStart?: (idx: number, performer: Performer) => void;
  onSlideChangeEnd?: (idx: number, performer: Performer) => void;
  onSlideChange?: (idx: number, performer: Performer, prevIdx: number) => void;
}

const GameCarousel: React.VFC<Props> = ({
  performers,
  guesses,
  onSlideChangeStart,
  onSlideChangeEnd,
  onSlideChange,
}) => {
  const navigationPrevRef = useRef<HTMLButtonElement>(null);
  const navigationNextRef = useRef<HTMLButtonElement>(null);

  const onBeforeInit = (swiper: SwiperCore): void => {
    if (typeof swiper.params.navigation !== 'boolean') {
      const navigation = swiper.params.navigation;
      if (navigation) {
        navigation.prevEl = navigationPrevRef.current;
        navigation.nextEl = navigationNextRef.current;
      }
    }
  };

  return (
    <SwiperStyled
      modules={[SwiperNavigation, SwiperEffectCoverflow]}
      navigation={{
        prevEl: navigationPrevRef.current,
        nextEl: navigationNextRef.current,
      }}
      onBeforeInit={onBeforeInit}
      onSlideChange={(swiper) =>
        onSlideChange?.(
          swiper.activeIndex,
          performers[swiper.activeIndex],
          swiper.previousIndex,
        )
      }
      onSlideChangeTransitionStart={(swiper) =>
        onSlideChangeStart?.(swiper.activeIndex, performers[swiper.activeIndex])
      }
      onSlideChangeTransitionEnd={(swiper) =>
        onSlideChangeEnd?.(swiper.activeIndex, performers[swiper.activeIndex])
      }
      effect="coverflow"
      coverflowEffect={{
        rotate: 0,
        stretch: -100,
        depth: 1000,
        scale: 0.5,
        modifier: 1,
        slideShadows: false,
      }}
      slidesPerView={2}
      centeredSlides
    >
      {performers.map((p, idx) => (
        <SwiperSlide key={idx}>
          <GameCarouselSlide
            performer={p}
            guess={guesses?.find((g) => g.performerId === p.id)}
          />
        </SwiperSlide>
      ))}
      <Navigation>
        <NavigationButton innerRef={navigationPrevRef}>
          <IcoArrowLeft />
        </NavigationButton>
        <NavigationButton innerRef={navigationNextRef}>
          <IcoArrowRight />
        </NavigationButton>
      </Navigation>
    </SwiperStyled>
  );
};

export default GameCarousel;
