import { Link } from '@material-ui/core';
import styled from 'styled-components';

export const LinkStyled = styled(Link).attrs({ underline: 'none' })`
  position: relative;
  background-color: ${({ theme }) => theme.palette.global01['300']};
  border-radius: 60px;
  text-align: left;
  font-family: 'Futura';
  font-weight: 700;
  color: #fff;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #fff;
  }
`;
