import 'assets/animation/eye-animation.css';

import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { useSprite } from 'hooks';

const AnimationWrap = styled(Box)`
  width: 200px;
  height: 100px;
`;

const ScaleWrap = styled(Box)`
  @media (-webkit-min-device-pixel-ratio: 2) {
    transform: scale(0.5);
  }
`;

interface Props {
  isReal?: boolean;
  onAnimationComplete: () => void;
}

const EyeAnimation: React.VFC<Props> = ({ isReal, onAnimationComplete }) => {
  const stopFrame = isReal !== undefined ? (isReal ? 50 : 25) : undefined;
  const currentFrame = useSprite(
    'ECHT-NAAR-SLECHT00',
    0,
    50,
    stopFrame,
    onAnimationComplete,
  );
  return (
    <AnimationWrap display="flex" alignItems="center" justifyContent="center">
      <ScaleWrap>
        <span className={currentFrame} />
      </ScaleWrap>
    </AnimationWrap>
  );
};

export default EyeAnimation;
